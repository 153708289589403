import { CommonButton, CustomTyphography, Flexbox, IconButton } from 'components'
import { ArrowLeftIcon } from 'components/icons';
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import { TextField } from '@mui/material';
import { FC, useState } from 'react';

const classes = classNames.bind(styles);

interface AddSourceSectionProps {
    handleCloseAddSourceSection: () => void
}

const AddSourceSection: FC<AddSourceSectionProps> = ({ handleCloseAddSourceSection }) => {
    const [sourceLink, setSourceLink] = useState('');

    return (
        <>
            <Flexbox align className={classes('sourcePopupLinked-heading', 'pb-4', 'gap-3')}>
                <IconButton className={classes('sourcePopup-actionButton')} onClick={handleCloseAddSourceSection}>
                    <ArrowLeftIcon />
                </IconButton>
                <CustomTyphography className={classes('sourcePopup-addTitle')}>Add New Knowledge Source</CustomTyphography>
            </Flexbox>
            <Flexbox align vertical fullWidth justifyBetween fullHeight className={classes('gap-4')}>
                <Flexbox vertical fullWidth className={classes('sourcePopupAdd-main', 'gap-4')}>
                    <Flexbox fullWidth justifyBetween align >
                        <CustomTyphography type='primary' className={classes('sourcePopupAdd-main__title')}>Add Source</CustomTyphography>
                        {/* <Select popupIcon value={'Jira'} options={['Jira']} /> */}
                    </Flexbox>
                    <Flexbox fullWidth className={classes('linkNodeInputBox')}>
                        <Flexbox align justify className={classes('linkNodeInputBox__left')}>
                            <CustomTyphography>Https://</CustomTyphography>
                        </Flexbox>
                        <Flexbox align justifyBetween fullWidth className={classes('linkNodeInputBox__right', 'gap-2')}>
                            <TextField
                                value={sourceLink}
                                placeholder='https://example.atlassian.net/browse/PROJ-XXXX'
                                className={classes('linkNodeInput')}
                                onChange={(e) => setSourceLink(e.target.value)}
                            />
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                <Flexbox fullWidth justify align vertical className={classes('sourcePopupAdd-footer', 'gap-2')}>
                    <CommonButton buttonSize='large' buttonType='primary' fullWidth>Add Source</CommonButton>
                    <CustomTyphography className={classes('sourcePopupAdd-footerText')}>Data will be imported when added</CustomTyphography>
                </Flexbox>
            </Flexbox>
        </>
    )
}

export default AddSourceSection