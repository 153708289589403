import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store';
import { addKnowledge, deleteKnowledgeSource, getGeneralKnowledge, getKBSources, getKnowledgeByType } from './knowledgeBase-api';
import { AttachmentMetadata, Block, GlossaryKnowledgeItem, KBTypes, TeamKnowledgeItem } from 'utils/types';

interface KnowledgeSource {
    id: number,
    name: string,
    sources: {
        source: string
    }[]
}

interface knowledgeBaseState {
    addKnowledgeLoading?: boolean
    addKnowledgeError?: string
    getKnowledgeByTypeLoading?: boolean
    getKnowledgeByTypeError?: string,
    knowledgeItems: (TeamKnowledgeItem | GlossaryKnowledgeItem)[],
    updateKnowledgeLoading?: boolean,
    updateKnowledgeError?: string
    knowledgeSources: KnowledgeSource[]
    getKBSourcesLoading?: boolean
    getKBSourcesError?: string
    deleteSourceLoading?: boolean
    blocks: Block[];
    retryUploadUuId: string | null
}
const initialState: knowledgeBaseState = {
    knowledgeItems: [],
    knowledgeSources: [],
    blocks: [],
    retryUploadUuId: null
}

const knowledgeBaseSlice = createSlice({
    name: 'knowledgeBaseNew',
    initialState,
    reducers: {
        deleteKbSource: (state, action: PayloadAction<{ sourceId: number, kbType?: KBTypes }>) => {
            state.knowledgeSources = state.knowledgeSources.filter(source => source.id !== action.payload.sourceId)
            state.knowledgeItems = action.payload.kbType === KBTypes.GENERAL ? state.knowledgeItems.filter(kbItem => kbItem.id !== action.payload.sourceId) : state.knowledgeItems.filter(kbItem => kbItem.knowledgeBaseId !== action.payload.sourceId)
        },
        setKBSources: (state, action: PayloadAction<KnowledgeSource[]>) => {
            state.knowledgeSources = action.payload
        },
        addBlockItem: (state, action: PayloadAction<Block>) => {
            state.blocks = [...state.blocks, action.payload]
        },
        replaceAttachment: (state, action: PayloadAction<Block<AttachmentMetadata>>) => {
            state.blocks = state.blocks.map((block) => {
                if ((block.metadata as AttachmentMetadata).fileName === action.payload.metadata.fileName) {
                    return action.payload
                } else {
                    return block;
                }
            })
        },
        updateAttachment: (state, action: PayloadAction<{ isError?: boolean, fileStatus: number, fileName: string }>) => {
            state.blocks = state.blocks.map((block) => {
                if ((block.metadata as AttachmentMetadata).fileName === action.payload.fileName) {
                    return { ...block, metadata: { ...block.metadata, ...action.payload } };
                } else {
                    return block;
                }
            })
        },
        setRetryUploadUuid: (state, action: PayloadAction<string | null>) => {
            state.retryUploadUuId = action.payload
        },
        deleteBlockItem: (state, action: PayloadAction<number>) => {
            state.blocks = state.blocks.filter(b => b.id !== action.payload)
        },
        cancelUpload: (state, action: PayloadAction<string>) => {
            state.blocks = state.blocks.filter(b => b.uuId !== action.payload)
        },
        updateBlockItem: (state, action: PayloadAction<any>) => {
            state.blocks = state.blocks.map((block) =>
                block.id === action.payload.id
                    ? { ...block, metadata: { title: action.payload.title, url: action.payload.url } }
                    : block
            )
        },
        clearBlocks: (state) => {
            state.blocks = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addKnowledge.pending, (state) => {
                state.addKnowledgeLoading = true;
                state.addKnowledgeError = undefined
            })
            .addCase(addKnowledge.fulfilled, (state, action) => {
                state.addKnowledgeLoading = false;
                const newItems = action.payload.type === KBTypes.GENERAL ? [action.payload] : action.payload.knowledgeItems
                state.knowledgeItems = [...state.knowledgeItems, ...newItems]
                state.knowledgeSources = [...state.knowledgeSources, { id: action.payload.id, sources: action.payload.sources, name: action.payload.name }]
            })
            .addCase(addKnowledge.rejected, (state, action) => {
                state.addKnowledgeLoading = false;
                state.addKnowledgeError = action.error.message;
            })
            .addCase(getKnowledgeByType.pending, (state) => {
                state.getKnowledgeByTypeLoading = true;
            })
            .addCase(getKnowledgeByType.fulfilled, (state, action) => {
                state.getKnowledgeByTypeLoading = false;
                state.knowledgeItems = action.payload
            })
            .addCase(getKnowledgeByType.rejected, (state, action) => {
                state.getKnowledgeByTypeLoading = false;
                state.getKnowledgeByTypeError = action.error.message;
            })
            .addCase(getGeneralKnowledge.pending, (state) => {
                state.getKnowledgeByTypeLoading = true;
            })
            .addCase(getGeneralKnowledge.fulfilled, (state, action) => {
                state.getKnowledgeByTypeLoading = false;
                state.knowledgeItems = action.payload
            })
            .addCase(getGeneralKnowledge.rejected, (state, action) => {
                state.getKnowledgeByTypeLoading = false;
                state.getKnowledgeByTypeError = action.error.message;
            })
            .addCase(getKBSources.pending, (state) => {
                state.getKBSourcesLoading = true;
            })
            .addCase(getKBSources.fulfilled, (state, action) => {
                state.getKBSourcesLoading = false;
                state.knowledgeSources = action.payload
            })
            .addCase(getKBSources.rejected, (state, action) => {
                state.getKBSourcesLoading = false;
                state.getKBSourcesError = action.error.message;
            })
            .addCase(deleteKnowledgeSource.pending, (state) => {
                state.deleteSourceLoading = true;
            })
            .addCase(deleteKnowledgeSource.fulfilled, (state, action) => {
                state.deleteSourceLoading = false;
            })
            .addCase(deleteKnowledgeSource.rejected, (state, action) => {
                state.deleteSourceLoading = false;
            })
    },
})

export const addKnowledgeLoadingSelector = (store: RootState) => store.knowledgeBaseNew.addKnowledgeLoading;
export const addKnowledgeErrorSelector = (store: RootState) => store.knowledgeBaseNew.addKnowledgeError;
export const getKnowledgeLoadingSelector = (store: RootState) => store.knowledgeBaseNew.getKnowledgeByTypeLoading;
export const getKnowledgeErrorSelector = (store: RootState) => store.knowledgeBaseNew.getKnowledgeByTypeError;
export const knowledgeItemsSelector = (store: RootState) => store.knowledgeBaseNew.knowledgeItems;
export const knowledgeSourcesSelector = (store: RootState) => store.knowledgeBaseNew.knowledgeSources;
export const knowledgeSourcesLoadingSelector = (store: RootState) => store.knowledgeBaseNew.getKBSourcesLoading;
export const knowledgeSourcesErrorSelector = (store: RootState) => store.knowledgeBaseNew.getKBSourcesError;
export const deleteSourceLoadingSelector = (store: RootState) => store.knowledgeBaseNew.deleteSourceLoading;
export const kbBlocksSelector = (store: RootState) => store.knowledgeBaseNew.blocks;
export const retryUploadUuIdSelector = (store: RootState) => store.knowledgeBaseNew.retryUploadUuId;

export const {
    deleteKbSource,
    setKBSources,
    addBlockItem,
    replaceAttachment,
    updateAttachment,
    setRetryUploadUuid,
    deleteBlockItem,
    cancelUpload,
    updateBlockItem,
    clearBlocks
} = knowledgeBaseSlice.actions;

export default knowledgeBaseSlice;
