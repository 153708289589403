import { TextField, type TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { FC, useRef } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Flexbox from 'components/Flexbox';
import CustomTyphography from 'components/CustomTyphography';
import { UploadIcon } from 'components/icons';

const classes = classNames.bind(styles);

type UploadFileInputProps = MuiTextFieldProps & {
    inputType?: 'filled' | 'outline';
    accept?: string;
    multiple?: boolean
    selectedFiles?: File[]
};

const UploadFileInput: FC<UploadFileInputProps> = ({
    className,
    error,
    helperText,
    label,
    inputType = 'filled',
    disabled,
    type,
    accept,
    placeholder,
    onChange,
    multiple,
    selectedFiles,
    ...props
}) => {
    // const fileInputRef = useRef<HTMLInputElement | null>(null);

    // const handleFileInputClick = () => {
    //     if (fileInputRef.current) {
    //         fileInputRef.current.click();
    //     }
    // };

    return (
        <Flexbox vertical fullWidth className={classes('commonInputContainer', 'upload', 'gap-2', className)}>
            {label && (
                <CustomTyphography className={classes('commonInput-label', disabled)}>
                    {label}
                </CustomTyphography>
            )}
            <Flexbox justifyBetween align fullWidth className={classes('commonInput', inputType)}>
                <Flexbox fullWidth align className={classes('gap-3')}>
                    <TextField
                        {...props}
                        fullWidth
                        className={classes('commonInput-field', 'upload', className, inputType)}
                        disabled={disabled}
                        type="text"
                        placeholder={placeholder}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <UploadIcon />
                        }}
                    />
                    {/* {type === 'file' && (
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept={accept}
                            style={{ display: 'none' }}
                            onChange={onChange}
                            multiple={multiple}
                        />
                    )} */}
                </Flexbox>
            </Flexbox>
            {/* {!!selectedFiles?.length && (
                <Flexbox align>
                    {selectedFiles.map((file) => file.name).join(', ')}
                </Flexbox>
            )}
            */}
            {error && helperText && <Flexbox><CustomTyphography className={classes('commonInput-error')}>{helperText}</CustomTyphography></Flexbox>}
        </Flexbox>
    );
};

export default UploadFileInput;
