import { Tabs, TabsProps } from '@mui/material'
import classNames from 'classnames/bind';
import { FC } from 'react'
import styles from './styles.module.scss'

const classes = classNames.bind(styles);

interface CustomTabsProps extends TabsProps {

}

const CustomTabs: FC<CustomTabsProps> = ({ className, ...props }) => {
    return (
        <Tabs
            className={classes('customTabs', className)}
            {...props}
        />
    )
}

export default CustomTabs