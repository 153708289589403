

import { ActionsMenu, Flexbox, IconButton, LinearProgress, Tooltip, } from 'components'
import { AttachmentMetadata, Block, } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { currentKbOwnerSelector } from 'store/knowledgeBase';
import { Actions, hasPermission } from 'utils/permissions';
import { CancelIcon, ReplayIcon } from 'components/icons';
const classes = classNames.bind(styles);

interface AttachmentItemProps {
    attachment: Block<AttachmentMetadata>;
    deleteAttachment: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    cancelAttachmentUpload: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    retryFileUpload: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
}

const AttachmentItem = ({ attachment, deleteAttachment, cancelAttachmentUpload, retryFileUpload }: AttachmentItemProps) => {
    const ownerSelector = useSelector(currentKbOwnerSelector);

    return (
        <Flexbox className={classes('attachmentContainer')}>
            <Flexbox className={classes('linkContainer')}>
                <a aria-disabled className={classes('linkText', { 'disabled-link': attachment.metadata.disabled })} href={attachment.metadata.signedUrl} target="_blank" rel="noopener">
                    {attachment.metadata.fileName}
                </a>
                {!attachment.metadata.disabled && hasPermission(Actions.delete, { owner: ownerSelector }) &&
                    <ActionsMenu
                        className={classes('linkActionsMenu')}
                        buttonItems={[
                            {
                                label: 'Delete',
                                action: deleteAttachment,
                                type: 'red'
                            }
                        ]}
                    />
                }
            </Flexbox>

            {
                !!attachment.metadata.fileStatus && <LinearProgress value={attachment.metadata.fileStatus} variant='determinate' className={classes('uploadProgress')} />
            }

            {attachment.metadata.isError &&
                <>
                    <Tooltip title="Retry">
                        <IconButton onClick={retryFileUpload} className={classes('actionIcon')}>
                            <ReplayIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                        <IconButton onClick={cancelAttachmentUpload} className={classes('actionIcon')}>
                            <CancelIcon />
                        </IconButton>
                    </Tooltip>
                </>
            }
        </Flexbox>
    )
}

export default AttachmentItem