import { Button, Flexbox, SearchField } from 'components'
import { ChangeEvent, FC } from 'react';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { PlusIcon } from 'components/icons';
import { GridApi } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
import * as timeago from 'timeago.js';

const classesInfo = classNames.bind(stylesInfo);

interface ViewsHeaderProps {
    gridApi: GridApi<any> | null,
    updateQueryPreference: (value: string) => void,
    searchValue: string,
    handleCreateView: () => void,
    emptyViews: boolean
}

const ViewsHeader: FC<ViewsHeaderProps> = ({
    gridApi,
    updateQueryPreference,
    searchValue,
    handleCreateView,
    emptyViews
}) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const updateQueryParam = (value: string) => {
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (!!value) {
            modifiedSearchParams.set('query', value)
        } else {
            modifiedSearchParams.delete('query')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
        updateQueryPreference(value);
    }

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateQueryParam(e.target.value)
    }

    const handleSearchClear = () => {
        updateQueryParam('')
    }

    return (
        <Flexbox vertical className={classesInfo('headerContainer')}>
            <Flexbox className={classesInfo('headerInfoTop')}>
                <Flexbox className={classesInfo('headerTitle')}>Execution Views</Flexbox>
                {
                    !emptyViews && (
                        <Flexbox>
                            {gridApi && (<ExportButton
                                api={gridApi}
                                formatExportedCellValue={
                                    (colId: string, value: any, formattedValue: any) => {
                                        if (colId === 'lastModifiedDate') {
                                            return value ? timeago.format(value) : '';
                                        } if ((colId === 'createdDate')) {
                                            return value ? new Date(value).toLocaleDateString() : '';
                                        } else {
                                            return formattedValue
                                        }
                                    }
                                }
                            />)}
                            {hasPermission(Actions.create) &&
                                <Button
                                    className={classesInfo('createButton')}
                                    variant='contained'
                                    onClick={handleCreateView}>
                                    Create
                                    <PlusIcon />
                                </Button>
                            }
                        </Flexbox>
                    )
                }
            </Flexbox>

            {!emptyViews && (
                <Flexbox className={classesInfo('headerInfo')}>
                    <Flexbox>
                        <SearchField
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onClear={handleSearchClear}
                            placeholder='Search View'
                            className={classesInfo('searchInput')}
                        />
                    </Flexbox>
                </Flexbox>
            )}
        </Flexbox>
    )
}

export default ViewsHeader;