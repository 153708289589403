import React, { ChangeEvent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { deleteInitiative, duplicateInitiative, getInitiatives } from './initiatives.api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { initiativesLoadingSelector, initiativesSelector } from 'store/initiatives';
import { Button, Flexbox, SearchField, ConfirmationDialog, FilterButton, Loader, DatePicker, Snackbar, RiskStatusBox } from 'components';
import classNames from 'classnames/bind';
import styles from './initiatives.module.scss';
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import { PreferenceValues, Initiative, InitiativePriority, InitiativeStatus, Preferences, CalendarPresetsKeys, PreferencesKeys, ButtonVariants } from 'utils/types';
import { getEnumKeyByEnumValue } from 'utils';
import { EmptyInitiativeIcon, GenerateIcon, ImportIcon, PlusIcon } from 'components/icons';
import { userSelector } from 'store/user';
import { useDebounce, useWorkspaceId } from 'utils/hooks';
import { Actions, hasPermission } from 'utils/permissions';
import { FilterOption } from 'components/FilterButton';
import { productsSelector } from 'store/products';
import { getProducts } from 'pages/Products/products.api';
import EmptyState from 'common/emptyState';
import { FilterValuesReducerAction } from 'pages/Products/productsList';
import { getCurrentMonthEndDate, getCurrentMonthStartDate, getCurrentYearEndDate, getCurrentYearStartDate, getPreviousMonthEndDate, getPreviousMonthStartDate, getPreviousQuarterEndDate, getPreviousQuarterStartDate, getNextMonthEndDate, getNextMonthStartDate, getNextQuarterEndDate, getNextQuarterStartDate, getNextYearEndDate, getNextYearStartDate, getQuarterEndDate, getQuarterStartDate } from 'utils/date';
import { Preset } from 'components/Datepicker';
import { getPreferences, updatePreferences } from 'common/preferences/index.api';
import ActionsButton from 'components/ActionsButton';
import AutocompleteDialog from './components/autocompleteDialog';
import { JiraImportPopup } from 'common/Story/components';
import { compareEnumValues } from 'utils/tableSorting';
import { availableIntegrationsSelector, userIntegrationsSelector, workspaceIntegrationSelector } from 'store/integrations';
import { getAvailableIntegrations, getWorkspaceIntegrations } from 'pages/Integrations/integrations.api';
import { setToLocalStorage } from 'utils/localStorage';
import { JIRA_AUTH_BASE_URL, REDIRECT_URL_KEY } from 'utils/constants';
import { createQueryParamsFromObject } from 'utils/request';
const classes = classNames.bind(styles);
const classesInfo = classNames.bind(stylesInfo);
import ExportButton from 'components/AgGridTable/components/ExportButton';
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable';
import { GridApi, GridReadyEvent, ICellRendererParams, KeyCreatorParams, SortChangedEvent } from 'ag-grid-community';
import * as timeago from 'timeago.js';
import { activeUsersSelector } from 'store/users-slice';
import { teamsSelector } from 'store/teams-slice';
import { useLazyGetTeamsQuery } from 'store/teams-api';
import { useLazyGetUsersQuery } from 'store/users-api';
import { scoresConfig } from './components/tabs/execution/constants';
import SidePanelDrawer from 'components/SidePanelDrawer';
import KBGenericSidebar from 'pages/KnowledgeBase/components/KBGenericSidebar';
import AlertsSidePanel from 'components/AlertsSidePanel';

interface InitiativeTableHeaders extends Initiative {
    actions?: string;
}

export enum FilterKeys {
    owner = 'owner',
    product = 'product',
    status = 'status',
    priority = 'priority',
    query = 'query',
    orderBy = 'orderBy',
    order = 'order',
    startDate = 'startDate',
    releaseDate = 'releaseDate',
    calendarSelect = 'calendarSelect',
    team = 'team',
    initiativeGridLayout = 'initiativeGridLayout'
}

const defaultFilterState = {
    [FilterKeys.owner]: [],
    [FilterKeys.product]: [],
    [FilterKeys.status]: [],
    [FilterKeys.priority]: [],
    [FilterKeys.team]: [],
}

const filterValuesReducer = (state: { [key: string]: FilterOption[] }, action: FilterValuesReducerAction) => {
    switch (action.type) {
        case 'update':
            return { ...state, [action.key]: action.payload }
        case 'reset':
            return defaultFilterState;
        default:
            return state;
    }
}

const InitiativesList = () => {
    const workspaceIntegrations = useSelector(workspaceIntegrationSelector);
    const userIntegrations = useSelector(userIntegrationsSelector);
    const availableIntegrations = useSelector(availableIntegrationsSelector);

    const [showIntegrationUnavailable, setIntegrationUnavailable] = useState(false);

    const [orderBy, setOrderBy] = useState<keyof InitiativeTableHeaders | undefined>();
    const [order, setOrder] = useState<'asc' | 'desc' | undefined>();
    const [searchValue, searchDebounceValue, setSearchValue] = useDebounce('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [initiativeId, setInitiativeId] = useState<number | undefined>();
    const [openDuplicateConfirmation, setOpenDuplicateConfirmation] = useState(false);

    const [data, setData] = useState<Initiative[]>([]);
    const [loading, setLoading] = useState(true);

    const [canUpdatePreferences, setCanUpdatePreferences] = useState(false);

    const [filterValues, setFilterValues] = useReducer(filterValuesReducer, defaultFilterState);
    const [gridStatePreferences, setGridStatePreferences] = useState<GridStatePreferences | undefined>({})

    const [userOptions, setUserOptions] = useState<FilterOption[]>([]);
    const [productOptions, setProductOptions] = useState<FilterOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<FilterOption[]>(Object.keys(InitiativeStatus).map((key, index) => ({ id: index, title: key })));
    const [priorityOptions, setPriorityOptions] = useState<FilterOption[]>(Object.keys(InitiativePriority).map((key, index) => ({ id: index, title: key })));
    const [teamOptions, setTeamOptions] = useState<FilterOption[]>([]);

    const [dateFrom, setDateFrom] = useState<Date | null>(getQuarterStartDate(new Date()));
    const [dateTo, setDateTo] = useState<Date | null>(getQuarterEndDate(new Date()));

    const [calendarSelect, setCalendarSelect] = useState<string | undefined>()
    const [presets, setPresets] = useState<Preset[]>([
        { id: CalendarPresetsKeys.lastQuarter, title: 'Last quarter' },
        { id: CalendarPresetsKeys.lastMonth, title: 'Last month' },
        { id: CalendarPresetsKeys.thisMonth, title: 'This month' },
        { id: CalendarPresetsKeys.nextMonth, title: 'Next month' },
        { id: CalendarPresetsKeys.thisQuarter, title: 'This quarter' },
        { id: CalendarPresetsKeys.nextQuarter, title: 'Next quarter' },
        { id: CalendarPresetsKeys.thisYear, title: 'This year' },
        { id: CalendarPresetsKeys.nextYear, title: 'Next year' },
    ])

    const [openJiraImportDialog, setOpenJiraImportDialog] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null)
    const [initiativeDuplicationSuccess, setInitiativeDuplicationSuccess] = useState<boolean>(false)
    const [duplicatingInitiatives, setDuplicatingInitiatives] = useState<number[]>([])

    const dispatch = useDispatch();
    const workspaceId = useWorkspaceId();
    const initiatives = useSelector(initiativesSelector);
    const initiativesLoading = useSelector(initiativesLoadingSelector);
    const user = useSelector(userSelector);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [getTeams] = useLazyGetTeamsQuery();
    const [getUsers] = useLazyGetUsersQuery()

    const users = useSelector(activeUsersSelector);
    const products = useSelector(productsSelector);
    const teams = useSelector(teamsSelector);

    const handleDuplicateInitiative = async () => {
        if (initiativeId) {

            try {
                setDuplicatingInitiatives(prev => [...prev, initiativeId])
                const duplicatedInitiative = (await dispatch(duplicateInitiative(initiativeId))) as unknown as Initiative
                setInitiativeDuplicationSuccess(true);

                setTimeout(() => {
                    setInitiativeDuplicationSuccess(false)
                    navigate(`/initiatives/initiative/${duplicatedInitiative.id}`)
                }, 800)
            } catch (err) {
                console.log(err);
            } finally {
                setDuplicatingInitiatives(prev => prev.filter(el => el !== initiativeId))
            }
        }
    }

    const columns: AgColumn[] = useMemo(() => {
        const columns: AgColumn[] = [
            {
                colType: ColumnTypes.Linked,
                headerName: 'Title',
                field: 'title',
                defaultValue: 'Untitled',
                minWidth: 250,
                sortable: true,
                link: '/initiatives/initiative',
                linkParam: 'id',
                wrapText: true,
                autoHeight: true,
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Owner',
                field: 'owner',
                minWidth: 200,
                sortable: true,
                valueGetter: (params: any) => {
                    return params.data ? params.data.owner?.fullName : '';
                },
                valueFormatter: (params: any) => {
                    return params?.value?.fullName;
                },
            },
            {
                colType: ColumnTypes.Progress,
                headerName: 'Progress',
                field: 'progress',
                sortable: true,
                minWidth: 200,
            },
            {
                colType: ColumnTypes.PRDScore,
                headerName: 'PRD Score',
                field: 'score',
                sortable: true,
                minWidth: 120,
            },
            {
                colType: ColumnTypes.Chip,
                headerName: 'Products',
                field: 'products',
                sortable: true,
            },
            {
                colType: ColumnTypes.Priority,
                headerName: 'Priority',
                field: 'priority',
                sortable: true,
            },
            {
                colType: ColumnTypes.SimpleSelect,
                headerName: 'Status',
                field: 'status',
                minWidth: 180,
                sortable: true,
                comparator: (d1: any, d2: any) => {
                    return compareEnumValues(d1, d2, InitiativeStatus)
                },
                valueFormatter: (params: any) => {
                    return getEnumKeyByEnumValue(InitiativeStatus, params.value) || ''
                }
            },
            {
                colType: ColumnTypes.Circle,
                headerName: 'Teams',
                field: 'teams',
                sortable: true,
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Development Start Date',
                field: 'startDate',
                minWidth: 120,
                sortable: true,
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Development End Date',
                field: 'endDate',
                minWidth: 120,
                sortable: true,
            },
            {
                colType: ColumnTypes.Date,
                headerName: 'Live Date',
                field: 'releaseDate',
                minWidth: 120,
                sortable: true,
            },
            {
                colType: ColumnTypes.TimeAgo,
                headerName: 'Last Updated',
                field: 'lastModifiedDate',
                minWidth: 120,
                sortable: true,
            },
            {
                colType: ColumnTypes.Action,
                field: 'actions',
                headerName: '',
                actions: (params: any) => {
                    return [
                        {
                            label: 'Open',
                            action: () => {
                                navigate(`/initiatives/initiative/${params?.node?.data?.id}`)
                            },
                        },
                        ...(hasPermission(Actions.create, !!params.data) ? [
                            {
                                label: 'Duplicate',
                                action: () => onOpenDuplicationDialog(params.data?.id),
                                isLoading: duplicatingInitiatives.includes(params.data?.id),
                                disabled: duplicatingInitiatives.includes(params.data?.id),
                            }
                        ] : []),
                        ...(hasPermission(Actions.delete, params.data) ? [
                            { label: 'Delete', action: () => showDeleteConfirmation(params.data.id), type: 'red' }
                        ] : []),
                    ]
                },
            },
        ]

        columns.push({
            headerName: 'Health',
            field: 'delayRisk',
            minWidth: 130,
            sortable: true,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== 'delayRisk') {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className={classes('h-full')}>
                            <RiskStatusBox category={value?.category} withoutAlerts />
                        </Flexbox> : null
                    }
                } else {
                    return (
                        <SidePanelDrawer
                            actionElement={
                                (props: any) => (
                                    <Flexbox align className={classes('h-full')} {...props}>
                                        <RiskStatusBox
                                            category={params.value?.category}
                                            alertsCount={params.value?.reasons?.length}
                                        // loading={evaluatingViews.includes(params.data.id)}
                                        />
                                    </Flexbox>
                                )
                            }
                        // disabled={evaluatingViews.includes(params.data.id)}
                        >
                            {
                                !!params.value?.reasons?.length && <AlertsSidePanel
                                    title={params.data?.name}
                                    reasons={params.value?.reasons}
                                    format={'markedown'}
                                    lastEvaluationDate={params.value?.lastEvaluationDate}
                                />
                            }
                        </SidePanelDrawer>
                    );
                }
            },
            comparator: (risk1: any, risk2: any) => {
                const risk1Value = risk1?.value;
                const risk2Value = risk2?.value;

                const isNullable = (value: any) => value === null || value === undefined;

                if (isNullable(risk1Value) && isNullable(risk2Value)) {
                    return 0;
                }
                if (isNullable(risk1Value)) {
                    return -1;
                }
                if (isNullable(risk2Value)) {
                    return 1;
                }

                if (risk1Value === 0 && risk2Value === 0) {
                    return 0;
                }
                if (risk1Value === 0) {
                    return -1;
                }
                if (risk2Value === 0) {
                    return 1;
                }

                return risk1Value > risk2Value ? 1 : risk1Value === risk2Value ? 0 : -1;
            },
            keyCreator: (params: KeyCreatorParams) => {
                return params.value ? params.value?.category : 'N/A'
            }
        },)
        return columns;
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                dispatch(getInitiatives()),
                getUsers({ workspaceId }),
                dispatch(getProducts()),
                getTeams({ workspaceId }),
                loadPreferences()
            ])
            setLoading(false)
        }

        fetchData();

    }, []);

    const loadPreferences = async () => {
        const preferences: Preferences<FilterKeys>[] = (await dispatch(getPreferences(PreferencesKeys.initiative))) as unknown as Preferences<FilterKeys>[];
        if (preferences && preferences.length) {

            const { initiativeGridLayout, order, orderBy, ...filters } = 'main' in preferences[0].value ? preferences[0].value.main : preferences[0].value;

            initiativeGridLayout && setGridStatePreferences(initiativeGridLayout as GridStatePreferences);
            setOrder(order);
            setOrderBy(orderBy)

            if (searchParams.toString().length === 0) {
                setSearchParams(filters, { replace: true })
            }
        }
    }


    useEffect(() => {
        if (canUpdatePreferences) {
            const ownerIds = filterValues[FilterKeys.owner].map(filter => filter.id);
            const statuses = filterValues[FilterKeys.status].map(filter => filter.title);
            const priorities = filterValues[FilterKeys.priority].map(filter => filter.title);
            const productIds = filterValues[FilterKeys.product].map(filter => filter.id);
            const teamIds = filterValues[FilterKeys.team].map(filter => filter.id);

            const filterKeys: PreferenceValues<FilterKeys> = {};

            if (ownerIds.length) {
                filterKeys[FilterKeys.owner] = ownerIds.join(',');
            }

            if (statuses.length) {
                filterKeys[FilterKeys.status] = statuses.join(',');
            }

            if (productIds.length) {
                filterKeys[FilterKeys.product] = productIds.join(',');
            }

            if (priorities.length) {
                filterKeys[FilterKeys.priority] = priorities.join(',');
            }

            if (teamIds.length) {
                filterKeys[FilterKeys.team] = teamIds.join(',');
            }

            if (searchDebounceValue.length) {
                filterKeys[FilterKeys.query] = searchDebounceValue;
            }
            if (order && orderBy) {
                filterKeys[FilterKeys.order] = order;
                filterKeys[FilterKeys.orderBy] = orderBy;
            }

            if (dateFrom && dateTo && calendarSelect === undefined) {
                filterKeys[FilterKeys.startDate] = dateFrom.toUTCString();
                filterKeys[FilterKeys.releaseDate] = dateTo.toUTCString();
            }

            if (calendarSelect) {
                filterKeys[FilterKeys.calendarSelect] = calendarSelect
            }

            dispatch(updatePreferences({ ...filterKeys, initiativeGridLayout: gridStatePreferences }, PreferencesKeys.initiative));

            setSearchParams(filterKeys, { replace: true });
        }
    }, [filterValues, searchDebounceValue, order, orderBy, dateTo]);

    useEffect(() => {

        if (!loading) {
            const ownerIdsString = searchParams.get(FilterKeys.owner);
            if (ownerIdsString) {
                const ownerIds = ownerIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.owner, payload: userOptions.filter(option => ownerIds.includes(option.id)) })
            }

            const productIdsString = searchParams.get(FilterKeys.product);
            if (productIdsString) {
                const productIds = productIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.product, payload: productOptions.filter(option => productIds.includes(option.id)) })
            }

            const statusesString = searchParams.get(FilterKeys.status);
            if (statusesString) {
                const statuses = statusesString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.status, payload: statusOptions.filter(option => statuses.includes(option.title)) })
            }

            const prioritiesString = searchParams.get(FilterKeys.priority);
            if (prioritiesString) {
                const priorities = prioritiesString.split(',');
                setFilterValues({ type: 'update', key: FilterKeys.priority, payload: priorityOptions.filter(option => priorities.includes(option.title)) })
            }

            const teamIdsString = searchParams.get(FilterKeys.team);
            if (teamIdsString) {
                const teamIds = teamIdsString.split(',').map(id => parseInt(id));
                setFilterValues({ type: 'update', key: FilterKeys.team, payload: teamOptions.filter(option => teamIds.includes(option.id)) })
            }

            const queryString = searchParams.get(FilterKeys.query);
            if (queryString) {
                setSearchValue(queryString);
            }

            const startDate = searchParams.get(FilterKeys.startDate);
            if (startDate) {
                setDateFrom(new Date(startDate));
            }

            const releaseDate = searchParams.get(FilterKeys.releaseDate);
            if (releaseDate) {
                setDateTo(new Date(releaseDate));
            }

            const presetType = searchParams.get(FilterKeys.calendarSelect)
            if (presetType) {
                setCalendarSelect(presetType)
            }

        }
    }, [loading])

    useEffect(() => {
        let data = [...initiatives];

        if (searchDebounceValue) {
            data = data.filter(initiative => initiative.title && initiative.title.toLowerCase().includes(searchDebounceValue.toLowerCase()))
        }

        data = data.filter(initiative => {
            if (!filterValues[FilterKeys.owner].length || filterValues[FilterKeys.owner].some(filter => filter.id === initiative.owner.id)) {
                return true;
            }
            return false;
        })

        data = data.filter(initiative => {
            const initiativeStatus = getEnumKeyByEnumValue(InitiativeStatus, initiative.status);
            if (!filterValues[FilterKeys.status].length || filterValues[FilterKeys.status].some(filter => filter.title === initiativeStatus)) {
                return true;
            }
            return false
        })

        data = data.filter(initiative => {
            const initiativePriority = getEnumKeyByEnumValue(InitiativePriority, initiative.priority);
            if (!filterValues[FilterKeys.priority].length || filterValues[FilterKeys.priority].some(filter => filter.title === initiativePriority)) {
                return true;
            }
            return false
        })

        data = data.filter(initiative => {
            if (!filterValues[FilterKeys.product].length || filterValues[FilterKeys.product].some(filter => initiative.products.find(product => product.id === filter.id))) {
                return true;
            }
            return false;
        })

        data = data.filter(initiative => {
            if (!filterValues[FilterKeys.team].length || filterValues[FilterKeys.team].some(filter => initiative.teams.find(team => team.id === filter.id))) {
                return true;
            }
            return false;
        })

        data = calendarFilter(data)

        setData(data)
    }, [initiatives, order, orderBy, searchDebounceValue, filterValues, dateFrom, dateTo]);

    const calendarFilter = (data: Initiative[]) => {
        if (dateFrom && dateTo) {
            const newData = data.filter(initiative => {
                const startDate = initiative.startDate && new Date(initiative.startDate)
                const releaseDate = initiative.releaseDate && new Date(initiative.releaseDate);

                if ((startDate && startDate >= dateFrom && startDate <= dateTo) || (releaseDate && releaseDate >= dateFrom && releaseDate <= dateTo) || (startDate && startDate <= dateFrom && releaseDate && releaseDate >= dateTo)) {
                    return true
                }
                return false
            })
            return newData
        }
        return data
    }

    useEffect(() => {
        const options: FilterOption[] = users.map(u => ({ id: u.id, title: `${u.fullName}${u.id === user.id ? ' (Me)' : ''}`, tooltip: u.email }))
        const currentUserIndex = options.findIndex((o) => o.id === user.id)
        if (currentUserIndex >= 0) {
            const currentUserOption = options.splice(currentUserIndex, 1)[0]
            options.splice(0, 0, currentUserOption)
        }
        setUserOptions(options)

    }, [users, user])

    useEffect(() => {
        setProductOptions(products.map(product => ({ id: product.id, title: product.title || '' })))
    }, [products])

    useEffect(() => {
        setTeamOptions(teams.map(team => ({ id: team.id, title: team.name })))
    }, [teams])

    useEffect(() => {
        if (calendarSelect === CalendarPresetsKeys.thisMonth) {
            setDateFrom(getCurrentMonthStartDate());
            setDateTo(getCurrentMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextMonth) {
            setDateFrom(getNextMonthStartDate());
            setDateTo(getNextMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.thisQuarter) {
            setDateFrom(getQuarterStartDate(new Date()));
            setDateTo(getQuarterEndDate(new Date()));
        } else if (calendarSelect === CalendarPresetsKeys.nextQuarter) {
            setDateFrom(getNextQuarterStartDate(new Date()));
            setDateTo(getNextQuarterEndDate(new Date()));
        } else if (calendarSelect === CalendarPresetsKeys.thisYear) {
            setDateFrom(getCurrentYearStartDate());
            setDateTo(getCurrentYearEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.nextYear) {
            setDateFrom(getNextYearStartDate());
            setDateTo(getNextYearEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.lastMonth) {
            setDateFrom(getPreviousMonthStartDate());
            setDateTo(getPreviousMonthEndDate());
        } else if (calendarSelect === CalendarPresetsKeys.lastQuarter) {
            setDateFrom(getPreviousQuarterStartDate());
            setDateTo(getPreviousQuarterEndDate());
        }

        setPresets((current) => {
            return current.map(p => ({ ...p, isSelected: p.id === calendarSelect }))
        })

    }, [calendarSelect])

    useEffect(() => {
        dispatch(getWorkspaceIntegrations());
        dispatch(getAvailableIntegrations());
    }, [])

    const createNewInitiative = () => {
        navigate('/initiatives/initiative');
    }

    const onSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        setCanUpdatePreferences(true);
    }

    const onSearchClear = () => {
        setSearchValue('');
        setCanUpdatePreferences(true);
    }

    const deleteInitiativeAction = async () => {
        if (initiativeId) {
            dispatch(deleteInitiative(initiativeId));
            onCancelDelete();
        }
    }

    const showDeleteConfirmation = (idInitiativeItem: number) => {
        setInitiativeId(idInitiativeItem)
        setOpenConfirmation(true)
    }

    const onCancelDelete = () => {
        setOpenConfirmation(false)
    }

    const onOpenDuplicationDialog = (idInitiativeItem: number) => {
        setInitiativeId(idInitiativeItem)
        setOpenDuplicateConfirmation(true);

    };

    const onCloseDuplicationDialog = () => {

        setOpenDuplicateConfirmation(false);
    };

    const onFilterValueChange = (targetName: string, value: FilterOption[]) => {
        setFilterValues({ type: 'update', key: targetName, payload: value })
        setCanUpdatePreferences(true)
    }

    const resetAllFilter = () => {
        setSearchValue('');
        setOrder(undefined);
        setOrderBy(undefined);
        setFilterValues({ type: 'reset' })
        setCanUpdatePreferences(true)
    }

    const onFilterReset = (filterName: string) => {
        setFilterValues({ type: 'update', key: filterName, payload: [] })
        setCanUpdatePreferences(true)
    }

    const onDateChange = ([startDate, dateTo]: [Date | null, Date | null]) => {
        setCalendarSelect(undefined)
        setDateFrom(startDate);
        setDateTo(dateTo);
        if (dateTo) {
            setCanUpdatePreferences(true)
        }
    };

    const selectCalendarRange = (id: string) => {
        setCalendarSelect(id)
        setCanUpdatePreferences(true)
    }

    const [openAutoCompleteDialog, setOpenAutoCompleteDialog] = useState(false);

    const onShowAutoComplete = () => {
        setOpenAutoCompleteDialog(true)
    }

    const connectJira = (callback: () => void) => {
        if (!workspaceIntegrations.find(integration => integration.name === 'JIRA')) {
            setIntegrationUnavailable(true);
            return;
        }
        if (!userIntegrations.find(integration => integration.name === 'JIRA')) {
            const jiraIntegration = availableIntegrations.find(
                integration => integration.name === 'JIRA'
            );
            if (jiraIntegration) {
                setToLocalStorage(REDIRECT_URL_KEY, location.pathname);
                const props = jiraIntegration.properties;
                const params = createQueryParamsFromObject({ ...props, state: 'JIRA' });

                window.location.replace(`${JIRA_AUTH_BASE_URL}&${params}`);
            }
        } else {
            callback();
        }
    };

    const importFromJira = () => {
        connectJira(async () => {
            setOpenJiraImportDialog(true)
        });
    }

    const closeJiraImportDialog = () => {
        setOpenJiraImportDialog(false)
    }

    const onJiraImport = (data: Initiative) => {
        navigate(`/initiatives/initiative/${data.id}`, { replace: true });
        closeJiraImportDialog();
    }

    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: onShowAutoComplete,
                text: 'Generate with AI',
                variant: ButtonVariants.outlined,
                icon: <GenerateIcon />,
            }, {
                onClick: createNewInitiative,
                text: 'From scratch',
                variant: ButtonVariants.outlined,
            }, {
                onClick: importFromJira,
                text: 'Import from Jira',
                variant: ButtonVariants.outlined,
                icon: <ImportIcon />,
            },)
        }

        return buttons
    }, [])

    const onSortChanged = (e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        if (order !== value?.sort || orderBy !== value?.colId) {
            modifiedSearchParamsObject.initiativeGridLayout = gridStatePreferences
            dispatch(updatePreferences(modifiedSearchParamsObject, PreferencesKeys.initiative));
        }
    }

    const onGridStateChanged = (data: GridStatePreferences) => {
        setGridStatePreferences(data)
        const updatedPreferences: any = {};

        searchParams.forEach((value, key) => {
            updatedPreferences[key] = value;
        });

        updatedPreferences.initiativeGridLayout = data
        dispatch(updatePreferences(updatedPreferences, PreferencesKeys.initiative));
    }

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    return (
        <Flexbox vertical fullWidth className={classes('mainContainer')}>
            <Flexbox vertical className={classesInfo('headerContainer')}>
                <Flexbox className={classesInfo('headerInfoTop')}>
                    <Flexbox className={classesInfo('headerTitle')}>PRDs / Initiatives</Flexbox>
                    {initiatives.length > 0 &&
                        <Flexbox>
                            {gridApi && (
                                <ExportButton
                                    api={gridApi}
                                    formatExportedCellValue={
                                        (colId: string, value: any, formattedValue: any) => {
                                            if ((colId === 'releaseDate' || colId === 'startDate')) {
                                                return value ? new Date(value).toLocaleDateString() : '';
                                            } else if (colId === 'lastModifiedDate') {
                                                return value ? timeago.format(value) : ''
                                            } else {
                                                return formattedValue
                                            }
                                        }
                                    }
                                />
                            )}
                            {hasPermission(Actions.create) &&
                                <ActionsButton
                                    buttonItems={[
                                        { label: 'From scratch', action: createNewInitiative, icon: <PlusIcon /> },
                                        { label: 'Generate with AI', action: onShowAutoComplete, icon: <GenerateIcon /> },
                                        { label: 'Import from Jira', action: importFromJira, icon: <ImportIcon /> },
                                    ]}
                                    className={classes('actionMenu')}
                                />
                            }
                        </Flexbox>
                    }
                </Flexbox>
                {initiatives.length > 0 &&
                    <Flexbox className={classesInfo('headerInfo')}>
                        <Flexbox>
                            <SearchField
                                value={searchValue}
                                onChange={onSearchValueChange}
                                onClear={onSearchClear}
                                placeholder='Search Initiative'
                                className={classesInfo('searchInput')}
                            />
                            <FilterButton
                                options={userOptions}
                                value={filterValues[FilterKeys.owner]}
                                onChange={(value) => onFilterValueChange(FilterKeys.owner, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.owner)}
                                multiple
                                label={'Owner'}
                                className={classesInfo('filterButton')}
                                keepFirstOption
                            />
                            <FilterButton
                                options={statusOptions}
                                value={filterValues[FilterKeys.status]}
                                onChange={(value) => onFilterValueChange(FilterKeys.status, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.status)}
                                multiple
                                sortAlphabetically={false}
                                label={'Status'}
                                className={classesInfo('filterButton')}
                            />
                            <FilterButton
                                options={priorityOptions}
                                value={filterValues[FilterKeys.priority]}
                                onChange={(value) => onFilterValueChange(FilterKeys.priority, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.priority)}
                                multiple
                                sortAlphabetically={false}
                                label={'Priority'}
                                className={classesInfo('filterButton')}
                            />
                            <FilterButton
                                options={productOptions}
                                value={filterValues[FilterKeys.product]}
                                onChange={(value) => onFilterValueChange(FilterKeys.product, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.product)}
                                multiple
                                label={'Products'}
                                className={classesInfo('filterButton')}
                            />
                            <FilterButton
                                options={teamOptions}
                                value={filterValues[FilterKeys.team]}
                                onChange={(value) => onFilterValueChange(FilterKeys.team, value)}
                                onFilterReset={() => onFilterReset(FilterKeys.team)}
                                multiple
                                label={'Team'}
                                className={classesInfo('filterButton')}
                            />
                            {Object.values(filterValues).some(v => v.length !== 0) &&
                                <Flexbox className={classesInfo('resetButtonContainer')}>
                                    <Button className={classesInfo('resetButton')} onClick={resetAllFilter}>
                                        Reset
                                    </Button>
                                </Flexbox>
                            }
                        </Flexbox>
                        <Flexbox className={classes('datePickerBox')}>
                            <DatePicker
                                monthsShown={2}
                                selectsRange
                                onChange={onDateChange}
                                startDate={dateFrom}
                                endDate={dateTo}
                                presets={{
                                    onPresetSelect: selectCalendarRange,
                                    presets,
                                }}
                                type='button'
                                dateFormat="MM.dd.yy"
                                placeholderText='mm.dd.yy'
                            />
                        </Flexbox>
                    </Flexbox>
                }
            </Flexbox>
            <Flexbox className={classes('tableContainer')}>
                {initiativesLoading ? (
                    <Flexbox fullWidth fullHeight align justify><Loader disableShrink /></Flexbox>
                ) : initiatives.length > 0 ? (
                    <AgGridTable
                        data={data}
                        columns={columns}
                        getContextMenuItems={params => {
                            if (params.defaultItems) {
                                return ([
                                    ...(params.defaultItems ?? []),
                                    {
                                        name: 'Open in new tab',
                                        action: () => {
                                            const rowData = params?.node?.data;
                                            const url = `/initiatives/initiative/${rowData.id}`;
                                            window.open(url, '_blank');
                                        }
                                    }
                                ]);
                            } else {
                                return []
                            }
                        }}
                        order={order}
                        orderBy={orderBy}
                        onSortChanged={onSortChanged}
                        onGridStateChanged={onGridStateChanged}
                        gridStatePreferences={gridStatePreferences}
                        onGridReady={onGridReady}
                        exportFileName='Initiatives'
                    />
                ) : (
                    <EmptyState
                        icon={<EmptyInitiativeIcon />}
                        title='There are no Initiatives yet'
                        titleSmall={buttonItemsEmptyState.length ? 'Create your first initiative' : undefined}
                        buttonItems={buttonItemsEmptyState}
                    />
                )}
            </Flexbox>
            <ConfirmationDialog
                open={openConfirmation}
                onClose={onCancelDelete}
                onConfirm={deleteInitiativeAction}
                confirmButtonStyle='danger'
                title='Delete this initiative?'
            >
                <Flexbox>
                    You're about to permanently delete this initiative, and all connected data will be lost.
                </Flexbox>
            </ConfirmationDialog>
            <ConfirmationDialog
                open={openDuplicateConfirmation}
                onClose={onCloseDuplicationDialog}
                title="Duplicate this initiative?"
                confirmButtonLabel="Duplicate"
                onConfirm={handleDuplicateInitiative}
            >
                <Flexbox>
                    Are you sure you want to duplicate this initiative?
                </Flexbox>
            </ConfirmationDialog>

            <AutocompleteDialog openAutoCompleteDialog={openAutoCompleteDialog} setOpenAutoCompleteDialog={setOpenAutoCompleteDialog} />

            <JiraImportPopup baseUrl='initiatives' open={openJiraImportDialog} onClose={closeJiraImportDialog} onImport={onJiraImport} dialogTitle='Epic link' requiredMappingField='initiativeMapping' />

            <Snackbar
                open={showIntegrationUnavailable}
                type="warning"
                onClose={() => setIntegrationUnavailable(false)}
            >
                <Flexbox vertical>
                    <Flexbox>Jira is not enabled for the workspace you are in.</Flexbox>
                    <Flexbox>It should be added as integration from administrator at first.</Flexbox>
                </Flexbox>
            </Snackbar>

            <Snackbar
                open={initiativeDuplicationSuccess}
                type="success"
            >
                <Flexbox vertical>
                    Initiative duplicated successfully !
                </Flexbox>
            </Snackbar>
        </Flexbox>
    )
}

export default InitiativesList
