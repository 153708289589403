import { CustomTyphography, Flexbox } from 'components'
import UploadFileInput from 'components/CommonInput/UploadFileInput'
import FileSelector from 'components/FileUploader'
import AttachmentItem from '../../attachment/attachmentItem'
import styles from '../styles.module.scss';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { AttachmentMetadata, Block } from 'utils/types';
import { FC } from 'react';
import { addBlockItem, cancelUpload, replaceAttachment, setRetryUploadUuid, updateAttachment } from 'store/knowledgeBase-slice';

const classes = classNames.bind(styles);

interface FileTabPros {
    deleteAttachment: (id: number) => void
    attachments: Block<AttachmentMetadata>[]
}

const FileTab: FC<FileTabPros> = ({ deleteAttachment, attachments }) => {
    const dispatch = useDispatch()

    const onUploadFinish = (uploadedItem: Block<AttachmentMetadata>) => {
        dispatch(replaceAttachment(uploadedItem))
    }

    const onUploadError = (fileName: string) => {
        dispatch(updateAttachment({ isError: true, fileStatus: 0, fileName }))
    }

    const onUploadProgress = (_fileName: string, status: number) => {
        dispatch(updateAttachment({ fileStatus: status, fileName: _fileName }))
    }

    const onFileUploadStart = (attachment: Block<AttachmentMetadata>) => {
        dispatch(addBlockItem(attachment))
    }

    const resetRetryCondition = () => {
        dispatch(setRetryUploadUuid(null))
    }

    const cancelAttachmentUpload = (uuId?: string) => {
        if (uuId) {
            dispatch(cancelUpload(uuId))
        }
    }

    const retryFileUpload = (uuId?: string) => {
        if (uuId) {
            dispatch(setRetryUploadUuid(uuId))
        }
    }

    return (
        <Flexbox vertical className={classes('uploadKbSource', 'gap-3')}>
            {/* ______________________Previous Version_________________________ */}
            <FileSelector
                url={'blocks'}
                buttonType='button'
                multiple
                buttonText='Add new attachment'
                onUploadFinish={onUploadFinish}
                onUploadProgress={onUploadProgress}
                onFileUploadStart={onFileUploadStart}
                onUploadError={onUploadError}
                resetRetryCondition={resetRetryCondition}
                accept={'.csv, .pdf, .xlsx, .txt'}
                buttonRenderer={(openFilePicker) => (
                    <UploadFileInput
                        onClick={openFilePicker}
                        className={classes('uploadKbSource-input')}
                        inputType="filled"
                        label={'Upload Your File'}
                        placeholder="Click here to upload"
                        helperText={'Connection Error. Retry Upload'}
                    />
                )}
            />
            <CustomTyphography className={classes('uploadKbSource-description')}>PDF, CSV, XLSX, TXT.</CustomTyphography>
            {/* ________________________Attachments___________________ */}
            <Flexbox vertical>
                {attachments.map(attachment => (
                    <AttachmentItem
                        attachment={attachment}
                        key={attachment.id || attachment.uuId}
                        deleteAttachment={() => deleteAttachment(attachment.id)}
                        cancelAttachmentUpload={() => cancelAttachmentUpload(attachment.uuId)}
                        retryFileUpload={() => retryFileUpload(attachment.uuId)}
                    />
                ))}
            </Flexbox>
        </Flexbox>
    )
}

export default FileTab