import { TextField, type TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { FC } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Flexbox from 'components/Flexbox';
import CustomTyphography from 'components/CustomTyphography';

const classes = classNames.bind(styles);

type CommonInputProps = MuiTextFieldProps & {
    inputType?: 'filled' | 'outline';
};

const CommonInput: FC<CommonInputProps> = ({
    className,
    error,
    helperText,
    label,
    inputType = 'filled',
    disabled,
    ...props
}) => {
    return (
        <Flexbox vertical fullWidth className={classes('commonInputContainer', 'gap-2', className)}>
            {label && (
                <CustomTyphography className={classes('commonInput-label', disabled)}>
                    {label}
                </CustomTyphography>
            )}
            <Flexbox justifyBetween align fullWidth className={classes('commonInput', inputType)}>
                <Flexbox fullWidth align className={classes('gap-3')}>
                    {/* ____________selector_________ */}
                    {/* <Flexbox></Flexbox> */}
                    <TextField
                        {...props}
                        fullWidth
                        className={classes('commonInput-field', className, inputType)}
                        disabled={disabled}
                    />
                </Flexbox>
                {/* ____________Action Icon_____________ */}
                {/* <Flexbox></Flexbox> */}
            </Flexbox>
            {error && helperText && <Flexbox justifyEnd><CustomTyphography className={classes('commonInput-error')}>{helperText}</CustomTyphography></Flexbox>}
        </Flexbox>
    );
};

export default CommonInput;
