import Flexbox from 'components/Flexbox'
import classNames from 'classnames/bind';
import styles from './styles.module.scss'
import CustomTyphography from 'components/CustomTyphography';
import { Link } from 'components';
import { DescriptionIcon } from 'components/icons';
import { FC } from 'react';
import { KBSource } from 'utils/types';

const classes = classNames.bind(styles);

const getFormattedDate = (value: number) => {
    const date = new Date(value);

    if (!isNaN(date.getTime())) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }
}

interface KBGenericSidebarProps {
    title: string
    source: KBSource
    createdDate: number
    lastModifiedDate: number
    fields: { key: string, value: string }[]
}

const KBGenericSidebar: FC<KBGenericSidebarProps> = ({
    title,
    source,
    createdDate,
    lastModifiedDate,
    fields
}) => {
    return (
        <Flexbox vertical fullWidth className={classes('kbSidebarContainer', 'gap-6')}>
            <Flexbox fullWidth align justifyBetween className={classes('pb-4', 'kbSidebar-titleContainer')}>
                <CustomTyphography type='primary' className={classes('kbSidebar-title')}>{title}</CustomTyphography>
                {/* <ActionsMenu
                    buttonItems={[
                        {
                            label: 'Edit Info',
                            action: () => console.log('edit'),
                        },
                        {
                            label: 'Delete',
                            action: () => console.log('delete'),
                        }
                    ]}
                /> */}
            </Flexbox>
            <Flexbox vertical fullWidth className={classes('gap-6', 'kbSidebar-mainContent')}>
                {/* _____________________GROUP1_____________________ */}
                <Flexbox vertical className={classes('gap-6')}>
                    {/* <CustomTyphography type='primary' className={classes('kbSidebar-subtitle')}>{mockedData.group1.title}</CustomTyphography> */}
                    <Flexbox vertical fullWidth className={classes('gap-5', 'kbSidebar-group')}>
                        {fields.map(field => (
                            <Flexbox key={field.key} vertical align className={classes('p-4', 'gap-5')}>
                                <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4', 'gap-4')}>
                                    <CustomTyphography type='primary'>{field.key}</CustomTyphography>
                                    <CustomTyphography className={classes('text-end')}>{field.value}</CustomTyphography>
                                </Flexbox>
                            </Flexbox>
                        ))}
                        {/* <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                            <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem')}>
                                <CustomTyphography type='primary'>Detail 1</CustomTyphography>
                                <Flexbox align justifyBetween className={classes('gap-2', 'kbSidebar-user')}>
                                    <CustomTyphography>{mockedData.group1.user?.name}</CustomTyphography>
                                    <Avatar>
                                        {getInitialsFromName(mockedData.group1.user?.name || '')}
                                    </Avatar>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox> */}
                        {/* <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                            <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                <CustomTyphography type='primary'>Detail 2</CustomTyphography>
                                <Flexbox align justifyBetween>
                                    <ProgressBar value={mockedData.group1.progress} />
                                </Flexbox>
                            </Flexbox>
                        </Flexbox> */}
                        <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                            <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                <CustomTyphography type='primary'>Created Date</CustomTyphography>
                                <CustomTyphography>{getFormattedDate(createdDate)}</CustomTyphography>
                            </Flexbox>
                        </Flexbox>
                        <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                            <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                <CustomTyphography type='primary'>Updated Date</CustomTyphography>
                                <CustomTyphography>{getFormattedDate(lastModifiedDate)}</CustomTyphography>
                            </Flexbox>
                        </Flexbox>
                        {/* <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                            <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'pb-4')}>
                                <CustomTyphography type='primary'>Detail 5</CustomTyphography>
                                <Flexbox align justifyBetween className={classes('kbSidebar-status', 'accentSuccess')}>
                                    <CustomTyphography>{mockedData.group1.status}</CustomTyphography>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox> */}
                        <Flexbox vertical align className={classes('p-4', 'gap-5')}>
                            <Flexbox justifyBetween align fullWidth className={classes('kbSidebar-groupItem', 'last')}>
                                <CustomTyphography type='primary'>Source</CustomTyphography>
                                <Flexbox align justifyBetween className={classes('kbSidebar-link')}>
                                    <DescriptionIcon />
                                    <Link to={source.source} target='_blank'>
                                        <CustomTyphography>{source.name}</CustomTyphography>
                                    </Link>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </Flexbox>
                </Flexbox>
                {/* _____________________GROUP2_____________________ */}
                {/* <Flexbox vertical className={classes('gap-6')}>
                    <CustomTyphography type='primary' className={classes('kbSidebar-subtitle')}>{mockedData.group2.title}</CustomTyphography>
                    <Flexbox vertical fullWidth className={classes('gap-2', 'kbSidebar-group')}>
                        {
                            mockedData.group2.details.map((detail, detailIdx) => {
                                return (
                                    <Flexbox key={detailIdx} vertical className={classes('kbSidebar-groupItem', 'pt-3', 'pb-3', 'group2')}>
                                        <Flexbox align className={classes('gap-2', 'kbSidebar-group2Item')}>
                                            <Avatar>
                                                ML
                                            </Avatar>
                                            <CustomTyphography type='primary'>{detail.title}</CustomTyphography>
                                        </Flexbox>
                                    </Flexbox>
                                )
                            })
                        }
                    </Flexbox>
                </Flexbox> */}
                {/* _____________________GROUP2_____________________ */}
                {/* <Flexbox vertical className={classes('gap-6')}>
                    <CustomTyphography type='primary' className={classes('kbSidebar-subtitle')}>{mockedData.description}</CustomTyphography>
                </Flexbox> */}
            </Flexbox>
        </Flexbox>
    )
}

export default KBGenericSidebar