
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { viewsApi } from './views-api'
import { View, Node, LinkNode, NodeRisk } from 'utils/types'
import { RootState } from 'store'

interface ViewsState {
    views: {
        id: number
        name: string
        createdDate: number
        lastModifiedDate: number
    }[],
    view: View,
    nodes: Node[],
    createViewError?: string,
    viewsLoading?: boolean,
    viewLoading?: boolean,
    viewError?: string,
    linkToTasksError?: string,
    evaluateNodeRiskError?: string,
    unlinkTaskError?: string,
    suggestedNodes?: LinkNode[],
    evaluateViewRiskError?: string
}


const initialState: ViewsState = {
    views: [],
    view: {} as View,
    nodes: [],
    createViewError: '',
    viewsLoading: false,
    viewLoading: false,
    viewError: '',
    linkToTasksError: '',
    unlinkTaskError: '',
    evaluateViewRiskError: '',
}

// function makeMenuItemFromView(view: View) {
//     return ({
//         title: view.name,
//         url: 'views/:id',
//         paramId: `${view.id}`,
//         component: ViewDashboard,
//     })
// }

const viewsSlice = createSlice({
    name: 'viewsSlice',
    initialState,
    reducers: {
        setCreateViewError: (state, action: PayloadAction<string>) => {
            state.createViewError = action.payload
        },
        setLinkToTasksError: (state, action: PayloadAction<string>) => {
            state.linkToTasksError = action.payload
        },
        setUnlinkTaskError: (state, action: PayloadAction<string>) => {
            state.unlinkTaskError = action.payload
        },
        setEvaluateNodeRiskError: (state, action: PayloadAction<string>) => {
            state.evaluateNodeRiskError = action.payload
        },
        deleteViewAction: (state, action: PayloadAction<number | undefined>) => {
            state.views = state.views.filter(el => el.id !== action.payload)
        },
        updateViewAction: (state, action: PayloadAction<{ viewId: number, name: string }>) => {
            state.views = state.views.map(view => {
                if (view.id === action.payload.viewId) {
                    return {
                        ...view,
                        name: action.payload.name,
                    }
                } else {
                    return view
                }
            })
        },
        setViewsData: (state, action: PayloadAction<View[]>) => {
            state.views = action.payload
        },
        setNodesData: (state, action: PayloadAction<Node[]>) => {
            state.nodes = action.payload
        },
        setViewName: (state, action: PayloadAction<string>) => {
            state.view.name = action.payload
        },
        updateSuggestedNodes: (state, action: PayloadAction<string>) => {
            if (!!state.suggestedNodes?.length) {
                state.suggestedNodes = state.suggestedNodes.filter(node => node.source !== action.payload)
            }
        },
        updateNodeRiskAction: (state, action: PayloadAction<{ nodeId: number, risk: NodeRisk }>) => {
            state.nodes = state.nodes.map(node => {
                if (action.payload.nodeId === node.id) {
                    return {
                        ...node,
                        risk: action.payload.risk
                    }
                } else {
                    return node
                }
            })
        },
        setEvaluateViewRiskError: (state, action: PayloadAction<string>) => {
            state.evaluateViewRiskError = action.payload
        },
        updateViewRiskAction: (state, action: PayloadAction<{ viewId: number, risk: NodeRisk }>) => {
            state.views = state.views.map(view => {
                if (action.payload.viewId === view.id) {
                    return {
                        ...view,
                        risk: action.payload.risk
                    }
                } else {
                    return view
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            viewsApi.endpoints.getViews.matchFulfilled,
            (state, { payload }) => {
                state.viewsLoading = false
                state.views = payload.map((view: View) => ({
                    id: view.id,
                    name: view.name,
                    createdDate: view.createdDate,
                    lastModifiedDate: view.lastModifiedDate,
                    risk: view.risk
                }))
            },
        ),
            builder.addMatcher(
                viewsApi.endpoints.getViews.matchPending,
                (state, { payload }) => {
                    state.viewsLoading = true
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.getView.matchFulfilled,
                (state, { payload }) => {
                    state.view = payload
                    state.viewLoading = false
                    state.viewError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.getView.matchPending,
                (state, { payload }) => {
                    state.viewLoading = true
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.getView.matchRejected,
                (state, { payload }) => {
                    let errorText = ''
                    if (payload?.data && typeof payload.data === 'object' && 'error' in payload.data) {
                        errorText = payload.data.error as string
                    }
                    state.viewError = errorText
                    state.viewLoading = false
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.createView.matchFulfilled,
                (state, { payload }) => {
                    state.createViewError = ''
                    const newView = {
                        id: payload.id,
                        name: payload.name,
                        createdDate: payload.createdDate,
                        lastModifiedDate: payload.lastModifiedDate
                    }
                    state.views = [...state.views, newView]
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.createView.matchPending,
                (state, { payload }) => {
                    state.createViewError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.createView.matchRejected,
                (state, { payload }) => {
                    let errorText = ''
                    if (payload?.data && typeof payload.data === 'object' && 'errorMessage' in payload.data) {
                        errorText = payload.data.errorMessage as string
                    }
                    state.createViewError = errorText
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.linkToTasks.matchFulfilled,
                (state, { payload }) => {
                    state.linkToTasksError = ''
                    state.nodes = state.nodes.map(node => {
                        return node.id === payload.id ? payload : node
                    })
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.linkToTasks.matchPending,
                (state, { payload }) => {
                    state.linkToTasksError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.linkToTasks.matchRejected,
                (state, { payload }) => {
                    let errorText = ''
                    if (payload?.data && typeof payload.data === 'object' && 'errorMessage' in payload.data) {
                        errorText = payload.data.errorMessage as string
                    } else if (payload?.data && typeof payload.data === 'object' && 'error' in payload.data) {
                        errorText = payload.data.error as string
                    }
                    state.linkToTasksError = errorText
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.getViewNodes.matchFulfilled,
                (state, { payload }) => {
                    state.nodes = payload
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.evaluateNodeRisk.matchFulfilled,
                (state, { payload }) => {
                    state.evaluateNodeRiskError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.evaluateNodeRisk.matchPending,
                (state, { payload }) => {
                    state.evaluateNodeRiskError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.evaluateNodeRisk.matchRejected,
                (state, { payload }) => {
                    let errorText = ''
                    if (payload?.data && typeof payload.data === 'object' && 'errorMessage' in payload.data) {
                        errorText = payload.data.errorMessage as string
                    }
                    state.evaluateNodeRiskError = errorText
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.removeLinkFromNode.matchFulfilled,
                (state, { payload }) => {
                    state.unlinkTaskError = ''
                    state.nodes = state.nodes.map(node => {
                        return node.id === payload.id ? payload : node
                    })
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.removeLinkFromNode.matchPending,
                (state, { payload }) => {
                    state.unlinkTaskError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.removeLinkFromNode.matchRejected,
                (state, { payload }) => {
                    let errorText = ''
                    if (payload?.data && typeof payload.data === 'object' && 'errorMessage' in payload.data) {
                        errorText = payload.data.errorMessage as string
                    }
                    state.unlinkTaskError = errorText
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.getSuggestedNodes.matchFulfilled,
                (state, { payload }) => {
                    state.suggestedNodes = payload
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.evaluateViewRisk.matchFulfilled,
                (state, { payload }) => {
                    state.evaluateViewRiskError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.evaluateViewRisk.matchPending,
                (state, { payload }) => {
                    state.evaluateViewRiskError = ''
                },
            ),
            builder.addMatcher(
                viewsApi.endpoints.evaluateViewRisk.matchRejected,
                (state, { payload }) => {
                    let errorText = ''
                    if (payload?.data && typeof payload.data === 'object' && 'errorMessage' in payload.data) {
                        errorText = payload.data.errorMessage as string
                    }
                    state.evaluateViewRiskError = errorText
                },
            )
    },
})

export const viewsSelector = (store: RootState) => store.views.views;
export const viewSelector = (store: RootState) => store.views.view;
export const nodesSelector = (store: RootState) => store.views.nodes;
export const createViewErrorSelector = (store: RootState) => store.views.createViewError;
export const viewsLoadingSelector = (store: RootState) => store.views.viewsLoading;
export const viewLoadingSelector = (store: RootState) => store.views.viewLoading;
export const viewErrorSelector = (store: RootState) => store.views.viewError;
export const linkToTasksErrorSelector = (store: RootState) => store.views.linkToTasksError;
export const viewNameSelector = (store: RootState) => store.views.view.name;
export const evaluateNodeRiskErrorSelector = (store: RootState) => store.views.evaluateNodeRiskError;
export const unlinkTaskErrorSelector = (store: RootState) => store.views.unlinkTaskError;
export const suggestedNodesSelector = (store: RootState) => store.views.suggestedNodes;
export const evaluateViewRiskErrorSelector = (store: RootState) => store.views.evaluateViewRiskError;

export const {
    setCreateViewError,
    setLinkToTasksError,
    deleteViewAction,
    updateViewAction,
    setViewsData,
    setNodesData,
    setViewName,
    setUnlinkTaskError,
    setEvaluateNodeRiskError,
    updateSuggestedNodes,
    updateNodeRiskAction,
    setEvaluateViewRiskError,
    updateViewRiskAction
} = viewsSlice.actions;

export default viewsSlice;
