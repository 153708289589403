import {
    FacebookOutlined,
    LinkedIn,
    Email,
    Article,
    PictureAsPdf,
    Close,
    Search,
    ArrowDropDown,
    ArrowDropUp,
    Add,
    CalendarMonth,
    DeleteOutline,
    AttachFile,
    Edit,
    MoreVert,
    ArrowBackSharp,
    PeopleOutlineSharp,
    ExpandMore,
    ExpandLess,
    ArrowUpward,
    ArrowDownward,
    SettingsOutlined,
    PersonOutlineSharp,
    CheckCircle,
    IntegrationInstructionsOutlined,
    LogoutOutlined,
    FileUploadOutlined,
    ReportProblem,
    Error,
    Check,
    Tune,
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    FormatListBulleted,
    FormatListNumbered,
    Link,
    Straighten,
    MapsUgcOutlined,
    SendOutlined,
    InsertDriveFileSharp,
    InsertLink,
    CheckCircleOutlined,
    HighlightOff,
    PendingOutlined,
    InfoOutlined,
    Update,
    Autorenew,
    Fullscreen,
    FullscreenExit,
    PersonOutlineOutlined,
    Download,
    MoveUp,
    MoveDown,
    Replay,
    Cancel,
    Grading,
    GridView,
    Language,
    MenuOpen,
    Chat,
    Description,
    ThumbDownOffAlt,
    ThumbUpOffAlt,
} from '@mui/icons-material';
export const FacebookIcon = FacebookOutlined;
export const LinkedInIcon = LinkedIn;
export const EmailIcon = Email;
export const DocIcon = Article;
export const PDFIcon = PictureAsPdf;
export const DriveFileIcon = InsertDriveFileSharp
export const CloseIcon = Close;
export const SearchIcon = Search;
export const ArrowDropDownIcon = ArrowDropDown;
export const ArrowDropUpIcon = ArrowDropUp;
export const PlusIcon = Add;
export const CalendarIcon = CalendarMonth;
export const DeleteIcon = DeleteOutline;
export const AttachFileIcon = AttachFile;
export const EditIcon = Edit;
export const MoreVertIcon = MoreVert;
export const ArrowBackIcon = ArrowBackSharp;
export const TeamIcon = PeopleOutlineSharp;
export const ExpandMoreIcon = ExpandMore;
export const ExpandLessIcon = ExpandLess;
export const ArrowUpwardIcon = ArrowUpward;
export const ArrowDownwardIcon = ArrowDownward;
export const MoveUpIcon = MoveUp;
export const MoveDownIcon = MoveDown;
export const SettingsOutlinedIcon = SettingsOutlined;
export const UserIcon = PersonOutlineSharp;
export const CheckCircleIcon = CheckCircle;
export const IntegrationsIcon = IntegrationInstructionsOutlined;
export const LogoutIcon = LogoutOutlined;
export const ExportIcon = FileUploadOutlined;
export const WarningIcon = ReportProblem;
export const ErrorIcon = Error;
export const CheckIcon = Check;
export const TuneIcon = Tune;
export const FormatBoldIcon = FormatBold;
export const FormatItalicIcon = FormatItalic;
export const FormatUnderlinedIcon = FormatUnderlined;
export const FormatListBulletedIcon = FormatListBulleted;
export const FormatListNumberedIcon = FormatListNumbered;
export const LinkIcon = Link;
export const MetricIcon = Straighten;
export const AssistantIcon = MapsUgcOutlined;
export const SendIcon = SendOutlined;
export const DependencyOpenIcon = PendingOutlined;
export const DependencyResolveIcon = CheckCircleOutlined;
export const DependencyDeclineIcon = HighlightOff;
export const DependencyAcceptedIcon = InsertLink;
export const InfoOutlinedIcon = InfoOutlined;
export const RegenerateIcon = Update;
export const GenerateIcon = Autorenew;
export const FullscreenIcon = Fullscreen;
export const FullscreenExitIcon = FullscreenExit;
export const PersonOutlineOutlinedIcon = PersonOutlineOutlined;
export const DownloadIcon = Download;
export const ReplayIcon = Replay;
export const CancelIcon = Cancel;
export const GradingIcon = Grading;
export const GridViewIcon = GridView;
export const MenuOpenIcon = MenuOpen;
export const LanguageIcon = Language;
export const ChatIcon = Chat;
export const DescriptionIcon = Description;
export const ThumbUpOffAltIcon = ThumbUpOffAlt;
export const ThumbDownOffAltIcon = ThumbDownOffAlt;

export { default as OverviewIcon } from './Overview';
export { default as InitiativeIcon } from './Initiative';
export { default as PortalIcon } from './Portal';
export { default as ReportIcon } from './Report';
export { default as KnowledgeBaseIcon } from './KnowledgeBase';
export { default as PriorityLowIcon } from './PriorityLow';
export { default as PriorityMediumIcon } from './PriorityMedium';
export { default as PriorityHightIcon } from './PriorityHigh';
export { default as PriorityCriticalIcon } from './PriorityCritical';
export { default as JiraIcon } from './Jira';
export { default as SlackIcon } from './Slack';
export { default as CloseOutlinedIcon } from './CloseOutlined';
export { default as ArrowCollapsedIcon } from './ArrowCollapsed';
export { default as ArrowExpendedIcon } from './ArrowExpended';
export { default as OkrIcon } from './Okr';
export { default as GoogleIcon } from './Google';
export { default as MicrosoftIcon } from './Microsoft';
export { default as AdminPanelIcon } from './AdminPanel';
export { default as ImportIcon } from './Import';
export { default as EmptyOkrIcon } from './EmptyOkr';
export { default as EmptyInitiativeIcon } from './EmptyInitiative';
export { default as EmptyProductIcon } from './EmptyProduct';
export { default as EmptyResourcesIcon } from './EmptyResources';
export { default as EmptyRoadmapIcon } from './EmptyRoadmap';
export { default as EmptyDependencyIcon } from './EmptyDependency';
export { default as EmptyReportIcon } from './EmptyReport';
export { default as EmptyMockupIcon } from './EmptyMockup';
export { default as EmptyKnowledgeBaseIcon } from './EmptyKnowledgeBase';
export { default as Logo } from './Logo';
export { default as CollapseIcon } from './Collapse';
export { default as ExpandIcon } from './Expand';
export { default as NotFoundIcon } from './NotFound';
export { default as DependencyIcon } from './Dependency';
export { default as RefineIcon } from './Refine';
export { default as IdeasIcon } from './Ideas';
export { default as BenefitsIcon } from './Benefits';
export { default as AiIcon } from './AI';
export { default as LastUpdateIcon } from './LastUpdate';
export { default as DataScience } from './DataScience';
export { default as Disc } from './Disc';
export { default as Coffee } from './Coffee';
export { default as ArrowFatLinesRight } from './ArrowFatLinesRight';
export { default as Settings } from './Settings';
export { default as ErrorAlert } from './ErrorAlert';
export { default as Fire } from './Fire';
export { default as HandWaving } from './HandWaving';
export { default as Flag } from './Flag';
export { default as Stars } from './Stars';
export { default as SquareHalf } from './SquareHalf';
export { default as TrafficSign } from './TrafficSign';
export { default as Crosshair } from './Crosshair';
export { default as Sticker } from './Sticker';
export { default as FileText } from './FileText';
export { default as Planet } from './Planet';
export { default as BookBookmark } from './BookBookmark';
export { default as PlusCircle } from './PlusCircle';
export { default as Tick } from './Tick';
export { default as Close } from './Close';
export { default as JiraSmallIcon } from './JiraSmall';
export { default as ErrorCircle } from './ErrorCircle';
export { default as GitBranch } from './GitBranch';
export { default as Plus } from './PlusIcon';
export { default as ArrowDownIcon } from './ArrowDown';
export { default as ArrowUpIcon } from './ArrowUp';
export { default as ArrowLeftIcon } from './ArrowLeft';
export { default as ArrowUp } from './ArrowUp';
export { default as ConfluenceIcon } from './Confluence';
export { default as UploadIcon } from './Upload';