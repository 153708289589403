import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import dependencies from './dependencies';
import globalError from './globalError';
import ideas from './ideas';
import initiatives from './initiatives';
import integrations from './integrations';
import knowledgeBase from './knowledgeBase';
import mainPreferences from './mainPreferences';
import okrs from './okrs';
import products from './products';
import reports from './reports';
import user from './user';
import workspaces from './workspaces';
import { commentsSlice } from './comments-slice';
import { commentsApi } from './comments-api';
import { productApi } from './product-api';
import productSlice from './product-slice';
import { okrApi } from './okr-api';
import okrSlice from './okr-slice';
import { dependencyApi } from './dependency-api';
import dependencySlice from './dependency-slice';
import reportSlice from './report-slice';
import { reportApi } from './report-api';
import usersSlice from './users-slice';
import { usersApi } from './users-api';
import teamsSlice from './teams-slice';
import { teamsApi } from './teams-api';
import metricsSlice from './metrics-slice';
import { metricsApi } from './metrics-api';
import initiativeScoresSlice from './initiativeScores-slice';
import { initiativeScoresApi } from './initiativeScores-api';
import { initiativeGradingApi } from './initiativeGrading-api';
import initiativeGradingSlice from './initiativeGrading-slice';
import viewsSlice from './views-slice';
import { viewsApi } from './views-api';
import assistantSlice from './assistant-slice';
import knowledgeBaseSlice from './knowledgeBase-slice';

const reducers = combineReducers({
    user,
    workspaces,
    initiatives,
    products,
    okrs,
    integrations,
    globalError,
    mainPreferences,
    dependencies,
    reports,
    ideas,
    knowledgeBase,
    assistant: assistantSlice.reducer,
    knowledgeBaseNew: knowledgeBaseSlice.reducer,
    comments: commentsSlice.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    product: productSlice.reducer,
    [productApi.reducerPath]: productApi.reducer,
    okr: okrSlice.reducer,
    [okrApi.reducerPath]: okrApi.reducer,
    dependency: dependencySlice.reducer,
    [dependencyApi.reducerPath]: dependencyApi.reducer,
    report: reportSlice.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    users: usersSlice.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    teams: teamsSlice.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
    metrics: metricsSlice.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    initiativeScores: initiativeScoresSlice.reducer,
    [initiativeScoresApi.reducerPath]: initiativeScoresApi.reducer,
    initiativeGrading: initiativeGradingSlice.reducer,
    [initiativeGradingApi.reducerPath]: initiativeGradingApi.reducer,
    views: viewsSlice.reducer,
    [viewsApi.reducerPath]: viewsApi.reducer,
})

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([
            commentsApi.middleware,
            productApi.middleware,
            okrApi.middleware,
            dependencyApi.middleware,
            reportApi.middleware,
            usersApi.middleware,
            teamsApi.middleware,
            metricsApi.middleware,
            initiativeScoresApi.middleware,
            initiativeGradingApi.middleware,
            viewsApi.middleware,
        ]),
});

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type Dispatch = typeof store.dispatch;
export type Store = typeof store;
export type GetState = typeof store.getState;

export default store