import { useState, MouseEvent, FC, useEffect } from 'react';
import { ActionsMenu, CustomTyphography, Flexbox, Tooltip, Accordion, AccordionDetails, AccordionSummary } from 'components'
import { NavLink, useLocation } from 'react-router-dom';
import { SidebarMenuItem } from 'utils/types';
import classNames from 'classnames/bind';
import styles from './menuItem.module.scss';
import { ButtonItem } from 'components/ActionsMenu';
import { ArrowDownIcon } from 'components/icons';
const classes = classNames.bind(styles);

interface MenuItemProps {
    item: SidebarMenuItem;
    onClick?: () => void;
    className?: string,
    isCollapsed?: boolean,
    titleClassName?: string,
    settings?: boolean,
    buttonItems?: ButtonItem[]
}

const MenuItem: FC<MenuItemProps> = ({
    item,
    onClick = () => { },
    className,
    isCollapsed,
    titleClassName,
    settings,
    buttonItems
}) => {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isAccordionCollapsed, setIsAccordionCollapsed] = useState<boolean>(() => {
        if (item.sections?.some(section => location.pathname.includes(section.url))) {
            return false
        }
        return true
    })

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (item.sections?.some(section => location.pathname.includes(section.url))) {
            setIsAccordionCollapsed(false);
        }
    }, [location, item.sections]);

    if (item.sections) {
        return (
            <Accordion className={classes('menu-accordion')} expanded={!isAccordionCollapsed}>
                <Tooltip placement="right" title={item.title}>
                    <AccordionSummary
                        expandIcon={!isCollapsed && <ArrowDownIcon />}
                        className={classes({ 'active': item.sections.some(section => location.pathname.includes(section.url)) })}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsAccordionCollapsed(prevState => !prevState);
                        }}
                    >
                        <Flexbox justifyBetween align fullWidth onClick={onClick} className={classes({ menuItem__settings: settings })}>
                            <Flexbox className={classes('menuItem-accordion', 'gap-2')} justify={isCollapsed}>
                                {
                                    item.icon && (
                                        <Flexbox>
                                            <item.icon />
                                        </Flexbox>
                                    )
                                }
                                {!isCollapsed && (
                                    <CustomTyphography type='primary' className={classes(titleClassName)}>{item.title}</CustomTyphography>
                                )}
                            </Flexbox>
                            {(settings && buttonItems) && (
                                <Flexbox className={'pr-4 h-fit'}>
                                    <ActionsMenu
                                        buttonItems={buttonItems}
                                    />
                                </Flexbox>
                            )}
                        </Flexbox>
                    </AccordionSummary>
                </Tooltip>
                {item.sections.map(section => (
                    <Tooltip placement="right" title={section.title}>
                        <NavLink to={`/${section.url}`}>
                            <AccordionDetails className={classes({ 'active': location.pathname.includes(section.url) }, { collapsed: isCollapsed })}>
                                <Flexbox justifyBetween align fullWidth onClick={onClick}>
                                    <Flexbox className={classes('menuItem-accordion')} justify={isCollapsed}>
                                        {!isCollapsed ? (
                                            <CustomTyphography type='primary' className={classes(titleClassName)}>{section.title}</CustomTyphography>
                                        ) : <CustomTyphography type='primary' className={classes(titleClassName)}>{section.title.charAt(0).toUpperCase()}</CustomTyphography>}
                                    </Flexbox>
                                </Flexbox>
                            </AccordionDetails>
                        </NavLink>
                    </Tooltip>
                ))}
            </Accordion>
        )
    }

    if (item.hasPopupMenu) {
        return (
            <Flexbox className={classes('menuItemContainer', className)}>
                <Flexbox className={classes('menuItem')} onClick={handleClick}>
                    {
                        item.icon && <Tooltip placement="right" title={isCollapsed ? 'Account' : ''}><Flexbox><item.icon /></Flexbox></Tooltip>
                    }
                    {!isCollapsed &&
                        <CustomTyphography className={classes('popupMenuItemTitle')} type='primary'>
                            {item.title}
                        </CustomTyphography>
                    }
                </Flexbox>
                <item.component anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
            </Flexbox>
        )
    }

    return item.subMenus?.length ? (
        <Flexbox className={classes('menuItemContainer')}>
            <Flexbox className={classes('menuItem')} onClick={onClick}>
                {
                    item.icon && <item.icon />
                }
                {!isCollapsed &&
                    <CustomTyphography type='primary'>
                        {item.title}
                    </CustomTyphography>
                }
            </Flexbox>
        </Flexbox>
    ) : (
        <NavLink
            key={item.url}
            to={item.paramId && item.url ? `/${item.url.replace(':id', item.paramId)}` : `/${item.url}`} className={({ isActive }) => {
                return classes('menuItemContainer', { 'active': isActive })
            }}
        >
            <Tooltip placement="right" title={item.title}>
                <Flexbox justifyBetween align fullWidth onClick={onClick} className={classes({ menuItem__settings: settings })}>
                    <Flexbox className={classes('menuItem')} justify={isCollapsed}>
                        {
                            item.icon && (
                                <Flexbox>
                                    <item.icon />
                                </Flexbox>
                            )
                        }
                        {!isCollapsed && (
                            <CustomTyphography type='primary' className={classes(titleClassName)}>{item.title}</CustomTyphography>
                        )}
                    </Flexbox>
                    {(settings && buttonItems) && (
                        // <IconButton className={classes('settingsBtn')}><Settings /></IconButton>
                        <Flexbox className={'pr-4 h-fit'}>
                            <ActionsMenu
                                buttonItems={buttonItems}
                            />
                        </Flexbox>
                    )}
                </Flexbox>
            </Tooltip>
        </NavLink>
    )
}

export default MenuItem