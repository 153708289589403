import { TextField } from '@mui/material'
import { ActionsMenu, CommonButton, CustomTyphography } from 'components'
import CommonInput from 'components/CommonInput'
import Flexbox from 'components/Flexbox'
import styles from '../styles.module.scss';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { addBlockItem } from 'store/knowledgeBase-slice';
import { createAction } from 'common/resourcesBlock/index.api';
import { Block, LinkMetadata } from 'utils/types';
import { FC, useEffect, useState } from 'react';
import { isValidHttpsUrl } from 'utils';
import { editKBLinkAttachment } from 'store/knowledgeBase-api';

const classes = classNames.bind(styles);

interface LinkTabProps {
    deleteAttachment: (id: number) => void
    links: Block<LinkMetadata>[]
}

const LinkTab: FC<LinkTabProps> = ({ deleteAttachment, links }) => {
    const dispatch = useDispatch()

    const [nodeLink, setNodeLink] = useState<{ link: string, text: string }>({ link: '', text: '' })
    const [errorMessage, setErrorMessage] = useState('')
    const [editingLinkId, setEditingLinkId] = useState<null | number>(null)
    const [nodeLinks, setNodeLinks] = useState(links)

    useEffect(() => {
        setNodeLinks(links)
    }, [links])

    const createLinkAction = async (data: Block<LinkMetadata>) => {
        dispatch(addBlockItem(data))
    }

    const onConfirm = async () => {
        try {
            setErrorMessage('')
            if (editingLinkId) {
                await dispatch(editKBLinkAttachment({ blockId: editingLinkId, data: { url: nodeLink.link, title: nodeLink.text } }))

                setNodeLinks(prev => prev.map(link => {
                    if (link.id === editingLinkId) {
                        return {
                            ...link,
                            metadata: {
                                ...link.metadata,
                                url: nodeLink.link,
                                title: nodeLink.text
                            }
                        }
                    } else {
                        return link
                    }
                }))
            } else {
                const linkMetadata = (await dispatch(createAction({ url: nodeLink.link, title: nodeLink.text }, 'blocks'))) as unknown as Block<LinkMetadata>
                createLinkAction(linkMetadata)
            }
            setNodeLink({ link: '', text: '' })
            setEditingLinkId(null)
        } catch (e: any) {
            if (e.data.error) {
                setErrorMessage(e.data.error)
            }
        }
    }

    const editLink = (link: Block<LinkMetadata>) => {
        setNodeLink({ link: link.metadata.url, text: link.metadata.title || '' })
        setEditingLinkId(link.id)
    }

    const onCancelEditing = () => {
        setNodeLink({ link: '', text: '' })
        setEditingLinkId(null)
    }

    useEffect(() => {
        if (!isValidHttpsUrl(nodeLink.link)) {
            setErrorMessage('Should be a valid url')
        } else {
            setErrorMessage('')
        }
    }, [nodeLink]);

    return (
        <>
            <Flexbox justifyBetween align>
                <CustomTyphography className={classes('addNewNodeTitle')}>
                    {editingLinkId ? 'Edit Link ' : 'Add New Link'}
                </CustomTyphography>
                {
                    editingLinkId && <CommonButton buttonType='secondary' onClick={onCancelEditing}>Cancel Editing</CommonButton>
                }
            </Flexbox>
            <CommonInput
                placeholder={'Link Title'}
                inputType="filled"
                value={nodeLink.text}
                onChange={(e) => { setNodeLink(prev => ({ link: prev.link, text: e.target.value })) }}
            />
            <Flexbox vertical className={classes('gap-5')}>
                <Flexbox fullHeight vertical>
                    <Flexbox fullWidth className={classes('linkNodeInputBox')}>
                        <Flexbox align justify className={classes('linkNodeInputBox__left')}>
                            <CustomTyphography>https://</CustomTyphography>
                        </Flexbox>
                        <Flexbox
                            align
                            justifyBetween
                            fullWidth
                            className={classes('linkNodeInputBox__right', 'gap-2')}
                        >
                            <TextField
                                value={nodeLink.link}
                                placeholder="example.com"
                                className={classes('linkNodeInput')}
                                onChange={e => setNodeLink(prev => ({ link: e.target.value, text: prev.text }))}
                            />
                            <CommonButton
                                disabled={!nodeLink.link || !!errorMessage}
                                onClick={onConfirm}
                                className={classes('linkNode-btn')}
                                buttonSize="medium"
                                buttonType="primary"
                            >
                                {editingLinkId ? 'Edit' : 'Link'}
                            </CommonButton>
                        </Flexbox>
                    </Flexbox>
                    {!!nodeLink.link && !!errorMessage && (
                        <Flexbox className="input-error">{errorMessage}</Flexbox>
                    )}
                </Flexbox>
            </Flexbox>
            <Flexbox vertical>
                {nodeLinks?.map(link => (
                    <Flexbox className={classes('linkContainer')}>
                        <a className={classes('linkText')} href={link.metadata.url} target="_blank" rel="noopener">
                            {link.metadata?.title || link.metadata.url}
                        </a>
                        <ActionsMenu
                            className={classes('linkActionsMenu')}
                            buttonItems={[
                                { label: 'Edit', action: () => editLink(link) },
                                { label: 'Delete', action: () => deleteAttachment(link.id), type: 'red' }
                            ]}
                        />
                    </Flexbox>
                ))}
            </Flexbox>
        </>
    )
}

export default LinkTab