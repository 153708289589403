import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQueryWithReauth } from 'utils/rtk';

export const viewsApi = createApi({
    reducerPath: 'views.api',
    baseQuery: rtkBaseQueryWithReauth,
    tagTypes: ['views'],
    endpoints: builder => ({
        getViews: builder.query({
            query: ({ workspaceId }) => `/workspaces/${workspaceId}/views`,
        }),
        getView: builder.query({
            query: ({ workspaceId, viewId }) => `/workspaces/${workspaceId}/views/${viewId}`,
        }),
        getViewNodes: builder.query({
            query: ({ workspaceId, viewId }) => `/workspaces/${workspaceId}/views/${viewId}/nodes`,
        }),
        createView: builder.mutation({
            query: ({ workspaceId, data }) => {
                return {
                    url: `/workspaces/${workspaceId}/views`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        linkToTasks: builder.mutation({
            query: ({ workspaceId, data, viewId, nodeId }) => {
                return {
                    url: `/workspaces/${workspaceId}/views/${viewId}/nodes/${nodeId}/link`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        editView: builder.mutation({
            query: ({ workspaceId, data, viewId }) => {
                return {
                    url: `/workspaces/${workspaceId}/views/${viewId}`,
                    method: 'PUT',
                    body: data,
                };
            },
        }),
        deleteView: builder.mutation({
            query: ({ workspaceId, viewId }) => {
                return {
                    url: `/workspaces/${workspaceId}/views/${viewId}`,
                    method: 'DELETE',
                };
            },
        }),
        removeLinkFromNode: builder.mutation({
            query: ({ workspaceId, viewId, nodeId, linkedNodeId }) => {
                return {
                    url: `/workspaces/${workspaceId}/views/${viewId}/nodes/${nodeId}/link/${linkedNodeId}`,
                    method: 'DELETE',
                };
            },
        }),
        evaluateNodeRisk: builder.mutation({
            query: ({ workspaceId, viewId, nodeId }) => {
                return {
                    url: `/workspaces/${workspaceId}/views/${viewId}/nodes/${nodeId}/risk`,
                    method: 'POST',
                };
            },
        }),
        getSuggestedNodes: builder.query({
            query: ({ workspaceId, viewId, nodeId }) => `/workspaces/${workspaceId}/views/${viewId}/nodes/${nodeId}/link/suggested`,
        }),
        evaluateViewRisk: builder.mutation({
            query: ({ workspaceId, viewId }) => {
                return {
                    url: `/workspaces/${workspaceId}/views/${viewId}/risk`,
                    method: 'POST',
                };
            },
        }),
    })
});

export const {
    useCreateViewMutation,
    useGetViewNodesQuery,
    useGetViewQuery,
    useGetViewsQuery,
    useLazyGetViewNodesQuery,
    useLazyGetViewQuery,
    useLazyGetViewsQuery,
    useLinkToTasksMutation,
    useEditViewMutation,
    useDeleteViewMutation,
    useRemoveLinkFromNodeMutation,
    useEvaluateNodeRiskMutation,
    useGetSuggestedNodesQuery,
    useLazyGetSuggestedNodesQuery,
    useEvaluateViewRiskMutation
} = viewsApi

