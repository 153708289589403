import { CustomTyphography, IconButton } from 'components'
import { ArrowDownIcon, ArrowUpIcon } from 'components/icons'
import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames/bind';

const classes = classNames.bind(styles);

interface SourcePrimaryHeadingProps {
    title: string,
    open: boolean,
    endIcon?: boolean
}

const SourcePrimaryHeading: FC<SourcePrimaryHeadingProps> = ({ title, open, endIcon }) => {
    return (
        <>
            <CustomTyphography className={classes('sourcePopup-primaryTitle')}>{title}</CustomTyphography>
            {endIcon && (
                <IconButton className={classes('sourcePopup-actionButton')}>
                    {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
            )}
        </>
    )
}

export default SourcePrimaryHeading