import { ActionsMenu, ConfirmationDialog, Flexbox, SearchField } from 'components'
import { ChangeEvent, FC, useState } from 'react'
import stylesInfo from 'common/infoHeader/infoHeader.module.scss';
import classNames from 'classnames/bind';
import ExportButton from 'components/AgGridTable/components/ExportButton';
import { GridApi } from 'ag-grid-community';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Actions, hasPermission } from 'utils/permissions';
import CreateEditViewDialog from 'components/CreateEditViewDialog';
import { useWorkspaceId } from 'utils/hooks';
import { viewNameSelector } from 'store/views-slice';
import { useDeleteViewMutation } from 'store/views-api';
import { useSelector } from 'react-redux';
import { ButtonItem } from 'components/ActionsMenu';
import { Source } from 'utils/types';

const classesInfo = classNames.bind(stylesInfo);

interface ViewsHeaderProps {
    gridApi: GridApi<any> | null,
    updateQueryPreference: (value: string) => void,
    searchValue: string,
    emptyNodes: boolean,
    viewSources: Source[]
}

const ViewHeader: FC<ViewsHeaderProps> = ({
    gridApi,
    updateQueryPreference,
    searchValue,
    emptyNodes,
    viewSources
}) => {
    const params = useParams();
    const workspaceId = useWorkspaceId();
    const navigate = useNavigate()

    const viewId = params['id'] ? parseInt(params['id']) : undefined;
    const viewName = useSelector(viewNameSelector)

    const [deleteView, { isLoading: isDeleteLoading }] = useDeleteViewMutation()

    const [searchParams, setSearchParams] = useSearchParams();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [createViewDialog, setCreateViewDialog] = useState({ open: false });

    const updateQueryParam = (value: string) => {
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (!!value) {
            modifiedSearchParams.set('query', value)
        } else {
            modifiedSearchParams.delete('query')
        }

        setSearchParams(modifiedSearchParams, { replace: true });
        updateQueryPreference(value);
    }

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateQueryParam(e.target.value)
    }

    const handleSearchClear = () => {
        updateQueryParam('')
    }

    const showDeleteConfirmation = () => {
        setOpenDeleteConfirmation(true)
    }

    const handleEditView = () => {
        setCreateViewDialog({ open: true })
    }

    const handleDeleteView = async () => {
        try {
            const res = await deleteView({ workspaceId, viewId }).unwrap()
            setOpenDeleteConfirmation(false)
            navigate('/views')
        } catch (err) {
            console.log(err);
        }
    }

    const onCancelDelete = () => {
        setOpenDeleteConfirmation(false)
    }

    const handleCloseDialog = () => {
        setCreateViewDialog({ open: false });
    };

    const buttonItems: ButtonItem[] = []

    if (hasPermission(Actions.edit)) {
        buttonItems.push({
            label: 'Rename',
            action: () => { handleEditView() }
        })
    }
    if (hasPermission(Actions.delete)) {
        buttonItems.push({
            action: () => { showDeleteConfirmation() },
            label: 'Delete',
            type: 'red'
        })
    }

    return (
        <Flexbox vertical className={classesInfo('headerContainer')}>
            <Flexbox justifyBetween className={classesInfo('headerInfoTop')}>
                <Flexbox className={classesInfo('headerTitle')}>{viewName}</Flexbox>
                {!emptyNodes && (
                    <Flexbox align>
                        <Flexbox>
                            {gridApi && (
                                <ExportButton
                                    api={gridApi}
                                    formatExportedCellValue={
                                        (colId: string, value: any, formattedValue: any) => {
                                            if (colId === 'linkNodes') {
                                                return Array.isArray(value) ? value.map(el => el.source).join(', ') : '';
                                            } else if (colId === 'risk') {
                                                return value ? `${value.category} ${!value.reasons?.length ? '' : `(${value.reasons.length} ${value.reasons.length === 1 ? 'Insight' : 'Insights'})`} ` : ''
                                            } else {
                                                return formattedValue
                                            }
                                        }
                                    }
                                />
                            )}
                        </Flexbox>
                        <ActionsMenu
                            buttonItems={buttonItems}
                        />
                    </Flexbox>
                )}
            </Flexbox>

            {!emptyNodes && (
                <Flexbox className={classesInfo('headerInfo')}>
                    <Flexbox>
                        <SearchField
                            value={searchValue}
                            onChange={handleSearchValueChange}
                            onClear={handleSearchClear}
                            placeholder='Search'
                            className={classesInfo('searchInput')}
                        />
                    </Flexbox>
                </Flexbox>
            )}
            <ConfirmationDialog
                open={openDeleteConfirmation}
                onClose={onCancelDelete}
                onConfirm={handleDeleteView}
                confirmButtonStyle='danger'
                title='Delete this view?'
                confirmButtonLoading={isDeleteLoading}
            >
                <Flexbox>
                    Are you sure you want to delete this view?
                </Flexbox>
            </ConfirmationDialog>
            {createViewDialog.open && (
                <CreateEditViewDialog
                    open={createViewDialog.open}
                    handleClosePopup={handleCloseDialog}
                    name={viewName}
                    viewId={viewId}
                    title={'Edit View'}
                    viewMode
                    sources={viewSources}
                />
            )}
        </Flexbox>
    )
}

export default ViewHeader;
