import { Tab, TabProps } from '@mui/material';
import styles from './styles.module.scss'
import classNames from 'classnames/bind';
import { FC } from 'react';

const classes = classNames.bind(styles);

interface CustomTabProps extends TabProps {

}

const CustomTab: FC<CustomTabProps> = ({ className, ...props }) => {
    return (
        <Tab
            className={classes('customTab', className)}
            key={props.value}
            label={props.label}
            {...props}
        />
    )
}

export default CustomTab;