import { CommonButton, CustomSnackbar, Dialog, Flexbox } from 'components';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import CommonInput from 'components/CommonInput';
import { AttachmentMetadata, Block, KBTypes, LinkMetadata, isAttachmentBlock, isLinkBlock } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { addKnowledgeErrorSelector, addKnowledgeLoadingSelector, clearBlocks, deleteBlockItem, kbBlocksSelector } from 'store/knowledgeBase-slice';
import CustomTabs from 'components/CustomTabs';
import CustomTab from 'components/CustomTabs/CustomTab';
import { addKnowledge, deleteBlockFromKB } from 'store/knowledgeBase-api';
import FileTab from './FileTab';
import LinkTab from './LinkTab';

const classes = classNames.bind(styles);

const sourceTabOptions = [
    { value: 'link', label: 'Link' },
    { value: 'manual', label: 'Manual' }
]

interface AddKnowledgeSourcePopupProps {
    open: boolean;
    handleClosePopup: () => void;
    kbType: KBTypes;
    description?: string;
}

const AddKnowledgeSourcePopup: FC<AddKnowledgeSourcePopupProps> = ({
    open,
    kbType,
    handleClosePopup,
    description,
}) => {
    const dispatch = useDispatch()

    const [knowledgeTitle, setKnowledgeTitle] = useState('')
    const [sourceType, setSourceType] = useState<'link' | 'manual'>('link');
    const [addError, setAddError] = useState<string>('')

    const addKnowledgeLoading = useSelector(addKnowledgeLoadingSelector);
    const addKnowledgeError = useSelector(addKnowledgeErrorSelector);
    const blocks = useSelector(kbBlocksSelector);

    const attachments = useMemo(() => {
        const attachmentsBlock = blocks.filter(b => isAttachmentBlock(b)) as Block<AttachmentMetadata>[]
        return attachmentsBlock
    }, [blocks]);

    const links = useMemo(() => {
        const linksBlock = blocks?.filter(b => isLinkBlock(b)) as Block<LinkMetadata>[]
        return linksBlock || []
    }, [blocks])

    const onClosePopup = () => {
        handleClosePopup();
        setKnowledgeTitle('');
        dispatch(clearBlocks())
        setAddError('')
    };

    const deleteAttachment = (id: number) => {
        dispatch(deleteBlockFromKB(id))
        dispatch(deleteBlockItem(id))
    }

    const onConfirm = async () => {
        try {
            await dispatch(addKnowledge({
                blockIds: sourceType === 'manual' ? attachments.map(block => block.id) : links.map(block => block.id),
                type: kbType,
                name: knowledgeTitle,
            }))
        } catch (err) {
            if (typeof err === 'object' && err !== null && 'errorMessage' in err) {
                setAddError((err as { errorMessage: string }).errorMessage);
            }

            setTimeout(() => {
                setAddError('');
            }, 3000);

            console.log(err, 'err');
        }

        onClosePopup()
    }

    return (
        <Dialog
            open={open}
            onClose={onClosePopup}
            title={'Add Knowledge Source'}
            description={description || 'Knowledge will be imported from the link.'}
            className={classes('addKnowledgeContainer', 'gap-4')}
            contentClassName="addKnowledgeContent"
        >
            <Flexbox fullWidth vertical className={classes('gap-6')}>
                <CommonInput
                    label={'Knowledge Title'}
                    placeholder={`New ${kbType}`}
                    inputType="filled"
                    value={knowledgeTitle}
                    onChange={(e) => { setKnowledgeTitle(e.target.value) }}
                    disabled={addKnowledgeLoading}
                />
                <CustomTabs
                    sx={{ width: '100%' }}
                    className={classes('w-full')}
                    value={sourceType}
                    onChange={(_event: ChangeEvent<{}>, value: 'link' | 'manual') => setSourceType(value)}
                >
                    {sourceTabOptions.map((tab) => (<CustomTab key={tab.value} value={tab.value} label={tab.label} />))}
                </CustomTabs>
                <Flexbox fullWidth vertical className={classes('gap-6')}>
                    {sourceType === 'manual' ? (
                        <FileTab deleteAttachment={deleteAttachment} attachments={attachments} />
                    ) : (
                        <LinkTab deleteAttachment={deleteAttachment} links={links} />
                    )}
                </Flexbox>
                <CommonButton
                    fullWidth
                    buttonSize="big"
                    buttonType="primary"
                    disabled={!knowledgeTitle || addKnowledgeLoading || (sourceType === 'link' ? links.length === 0 : attachments.length === 0)}
                    loading={addKnowledgeLoading}
                    onClick={onConfirm}
                >
                    Add Knowledge Source
                </CommonButton>
            </Flexbox>
            <CustomSnackbar open={!!addKnowledgeError || !!addError} type='error' title={addKnowledgeError || addError} />
        </Dialog>
    );
};

export default AddKnowledgeSourcePopup;
