import { GridReadyEvent, ICellRendererParams, SortChangedEvent } from 'ag-grid-community'
import { GridApi } from 'ag-grid-enterprise'
import classNames from 'classnames/bind'
import EmptyState from 'common/emptyState'
import { CustomTyphography, Flexbox, Loader } from 'components'
import AgGridTable, { AgColumn, ColumnTypes, GridStatePreferences } from 'components/AgGridTable'
import { DataScience } from 'components/icons'
import AddKnowledgeSourcePopup from 'pages/KnowledgeBase/components/AddKnowledgeSourcePopup'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Actions, hasPermission } from 'utils/permissions'
import styles from './styles.module.scss'
import KBTeamsHeader from './KBTeamsHeader'
import { KBTypes } from 'utils/types'
import { useDispatch, useSelector } from 'react-redux'
import { getKnowledgeLoadingSelector, knowledgeItemsSelector } from 'store/knowledgeBase-slice'
import { getKnowledgeByType } from 'store/knowledgeBase-api'
import SidePanelDrawer from 'components/SidePanelDrawer'
import KBGenericSidebar from 'pages/KnowledgeBase/components/KBGenericSidebar'

const classes = classNames.bind(styles);

let timeoutId: NodeJS.Timeout;

export enum FilterKeys {
    query = 'query',
    order = 'order',
    orderBy = 'orderBy',
    kbTeams = 'kbTeams',
}

export interface IPreferencesData {
    query?: string,
    order?: 'asc' | 'desc',
    orderBy?: keyof Node,
    kbTeams?: any,
}

const KBSidePanel = ({ params }: { params: any }) => {
    return <SidePanelDrawer
        containerClassName={classes('kbSidepanel')}
        actionElement={
            (props: any) => (
                <Flexbox align className={'h-full w-full'} {...props}>
                    <CustomTyphography type='primary' className={'break-word'}>
                        {params.value}
                    </CustomTyphography>
                </Flexbox>
            )
        }
    >
        <KBGenericSidebar
            title={params.data.data.team_name}
            source={params.data.datasource}
            createdDate={params.data.createdDate}
            lastModifiedDate={params.data.lastModifiedDate}
            fields={[
                {
                    key: 'Team Name',
                    value: params.data.data.team_name,
                },
                {
                    key: 'Team Lead',
                    value: params.data.data.team_lead,
                },
            ]}
        />
    </SidePanelDrawer>
}

const KBTeams = () => {
    const dispatch = useDispatch();
    const preferencesRef = useRef<IPreferencesData>({});

    const [searchParams, setSearchParams] = useSearchParams();

    const knowledgeItems = useSelector(knowledgeItemsSelector);
    const getKnowledgeLoading = useSelector(getKnowledgeLoadingSelector)

    const [openAddSourcePopup, setOpenAddSourcePopup] = useState(false);
    const [gridApi, setGridApi] = useState<GridApi<any> | null>(null);
    const [debouncedQuery, setDebouncedQuery] = useState<null | string>(null);
    const [searchValue, setSearchValue] = useState('');
    const [filteredData, setFilteredData] = useState(knowledgeItems)

    const columns: AgColumn[] = useMemo(() => [
        {
            headerName: 'Name',
            field: 'data.team_name',
            minWidth: 250,
            sortable: true,
            pinned: 'left' as boolean | 'left' | 'right' | null | undefined,
            suppressMovable: true,
            wrapText: true,
            autoHeight: true,
            cellClass: 'ag-custom-cell cursor-pointer',
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== 'data.team_name') {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className={classes('h-full')}>
                            <CustomTyphography type='primary' className={'break-word'}>
                                {params.value}
                            </CustomTyphography>
                        </Flexbox> : null
                    }
                } else {
                    return <KBSidePanel params={params} />;
                }
            },
        },
        {
            headerName: 'Team Lead',
            field: 'data.team_lead',
            minWidth: 150,
            sortable: true,
            cellClass: 'cursor-pointer',
            cellRenderer: (params: ICellRendererParams) => {
                if (params.node.group) {
                    if (params.node.field !== 'data.team_lead') {
                        return null
                    } else if (params.node.groupData) {
                        const value = params.node.groupData['ag-Grid-AutoColumn']
                        return value ? <Flexbox align className={classes('h-full')}>
                            <CustomTyphography type='primary' className={'break-word'}>
                                {params.value}
                            </CustomTyphography>
                        </Flexbox> : null
                    }
                } else {
                    return <KBSidePanel params={params} />;
                }
            },
        },
        {
            colType: ColumnTypes.Source,
            headerName: 'Sources',
            field: 'datasource',
            minWidth: 150,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Created Date',
            field: 'createdDate',
            minWidth: 130,
            sortable: true,
        },
        {
            colType: ColumnTypes.Date,
            headerName: 'Updated Date',
            field: 'lastModifiedDate',
            minWidth: 130,
            sortable: true,
        },
    ], []);

    useEffect(() => {
        dispatch(getKnowledgeByType(KBTypes.TEAM))
    }, [])

    useEffect(() => {
        if (debouncedQuery !== null) {
            // dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionView));
        }

        return () => clearTimeout(timeoutId);
    }, [debouncedQuery])

    useEffect(() => {
        if (knowledgeItems) {
            const filteredItems = knowledgeItems.filter((item: any) => item.data?.team_name?.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase()))
            setFilteredData(filteredItems)
        }
    }, [searchValue, knowledgeItems])

    // _____________GRID Functions______________
    const buttonItemsEmptyState = useMemo(() => {
        const buttons = []

        if (hasPermission(Actions.create)) {
            buttons.push({
                onClick: () => { setOpenAddSourcePopup(true) },
                text: 'Add Knowledge Source',
            })
        }
        return buttons
    }, [])

    const updateQueryPreference = useCallback((value: string) => {
        setSearchValue(value);
        preferencesRef.current.query = value;

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            setDebouncedQuery(value);
        }, 500);

    }, [preferencesRef])

    const onGridReady = useCallback((e: GridReadyEvent) => {
        setGridApi(e.api)
    }, [])

    const onSortChanged = useCallback((e: SortChangedEvent) => {
        const value = e.api.getColumnState().find(s => s.sort !== null)
        const modifiedSearchParams = new URLSearchParams(searchParams);

        if (value) {
            modifiedSearchParams.set('order', value.sort || 'asc')
            modifiedSearchParams.set('orderBy', value.colId)
        } else {
            modifiedSearchParams.delete('order')
            modifiedSearchParams.delete('orderBy')
        }

        setSearchParams(modifiedSearchParams, { replace: true });

        const modifiedSearchParamsObject: any = {};
        modifiedSearchParams.forEach((value, key) => {
            modifiedSearchParamsObject[key] = value;
        });

        // if (preferencesRef.current.order !== value?.sort || preferencesRef.current.orderBy !== value?.colId) {
        //     preferencesRef.current = { executionViewGridLayout: preferencesRef.current.executionViewGridLayout, ...modifiedSearchParamsObject }
        //     dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
        // }
    }, [preferencesRef, searchParams])

    const onGridStateChanged = useCallback((data: GridStatePreferences) => {
        // preferencesRef.current.executionViewGridLayout = data

        // dispatch(updatePreferences(preferencesRef.current, PreferencesKeys.executionViewGridLayout));
    }, [preferencesRef]);

    return (
        <Flexbox fullWidth vertical>
            {
                getKnowledgeLoading ? (
                    <Flexbox fullWidth fullHeight align justify>
                        <Loader disableShrink />
                    </Flexbox>
                ) : knowledgeItems.length === 0 ? (
                    <EmptyState
                        icon={<DataScience />}
                        title='Teams’ Knowledge'
                        titleSmall={'All your teams’ knowledge will be imported from the link'}
                        buttonItems={buttonItemsEmptyState}
                    />
                ) : (
                    <>
                        <KBTeamsHeader
                            gridApi={gridApi}
                            updateQueryPreference={updateQueryPreference}
                            searchValue={searchValue}
                            emptyState={Array.isArray(knowledgeItems) && knowledgeItems.length === 0}
                        />
                        <Flexbox fullHeight className={classes('kbTeamsTableContainer')}>
                            <AgGridTable
                                data={filteredData}
                                columns={columns}
                                onGridReady={onGridReady}
                                exportFileName={'Knowledge Base Teams'}
                                onSortChanged={onSortChanged}
                                order={preferencesRef.current.order}
                                orderBy={preferencesRef.current.orderBy}
                                gridStatePreferences={preferencesRef.current.kbTeams}
                                onGridStateChanged={onGridStateChanged}
                            />
                        </Flexbox>
                    </>
                )
            }
            <AddKnowledgeSourcePopup
                open={openAddSourcePopup}
                handleClosePopup={() => setOpenAddSourcePopup(false)}
                kbType={KBTypes.TEAM}
                description='Team knowledge will be imported from the link.'
            />
        </Flexbox>
    )
}

export default KBTeams